import Flex from '../Flex/Flex';
import './Modal.scss';

export default function Modal({ show, cmp }) {
  return (
    <Flex className={`modal-container${show ? ' one' : ''}`}>
      <div className="modal-background">
        <div className="modal">{cmp}</div>
      </div>
    </Flex>
  );
}
