import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateLicense from './Components/CreateUser';
import UsersService from '../../services/Users.service';
import { useNavigate } from 'react-router-dom';

export default function useUsers() {
  const [dataTable, setDataTable] = useState(null);
  const [abm, setAbm] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [buttons, setButtons] = useState([
    {
      action: { edit: true },
      iconName: 'Pencil',
      iconSize: 20,
    },
    {
      action: { erase: true },
      iconName: 'Trash',
      iconSize: 20,
    },
  ]);

  const getData = async () => {
    const data = await UsersService.get();
    setDataTable({
      type: t('students'),
      header: [t('name'), t('surname'), t('email'), t('birthdate'), t('dni'), t('actions')],
      attributes: ['name', 'surname', 'email', 'birthdate', 'dni', ''],
      body: data,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const goBack = () => {
    setAbm(null);
    getData();
  };

  const handleDecision = ({ id, erase, edit }) => {
    if (erase) {
      // deleteItemModel(id);
      return;
    }

    const data = dataTable.body.find((it) => it.id === id);
    setAbm(<CreateLicense goBack={goBack} data={data} />);
  };

  const handleGoBack = (e) => {
    e?.preventDefault();
    if (abm) {
      goBack();
      return;
    }
    !abm && navigate('/');
  };

  return {
    dataTable,
    handleDecision,
    handleGoBack,
    t,
    abm,
    buttons,
  };
}
