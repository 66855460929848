import { Calendar } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import './Calendar.scss';
import useBigCalendar from './useBigCalendar';
import Flex from '../../components/Flex/Flex';
import CreateEvent from './components/CreateEvent';
import Nav from '../../components/Nav/Nav';
import CalendarManager from './components/CalendarManager';
import Message from './components/Message';
import Button from '../../components/Button/Button';

const DnDCalendar = withDragAndDrop(Calendar);

export default function BigCalendar() {
  const {
    t,
    eventsCalendar,
    localizer,
    handleSelect,
    handleCloseCreate,
    showCreate,
    showMessage,
    calendars,
    name,
    newDate,
    handleName,
    handleSubmitCalendar,
    handleSelectCalendar,
    handleEventCalendar,
    handleFinishEvent,
    handleDeleteEvent,
    handleMessage,
    isEditor,
  } = useBigCalendar();

  return (
    <>
      <Nav title={t('calendar.title')}>
        <form onSubmit={handleSubmitCalendar}>
          {isEditor && (
            <Flex>
              <input required type="text" value={name} onChange={handleName} placeholder={t('calendar.new')} />
              <Button type="submit" primary label={t('calendar.create')} />
            </Flex>
          )}
        </form>
      </Nav>
      <CalendarManager t={t} handleSelectCalendar={handleSelectCalendar} calendars={calendars} />
      {eventsCalendar?.data && (
        <Flex column className="calendar">
          {showCreate && (
            <CreateEvent
              t={t}
              handleClose={handleCloseCreate}
              newDate={newDate}
              handleFinishEvent={handleFinishEvent}
              calendarId={eventsCalendar.id}
            />
          )}
          {showMessage.show && (
            <Message
              t={t}
              isEditor={isEditor}
              handleDelete={handleDeleteEvent}
              handleClose={handleMessage}
              data={showMessage.data}
            />
          )}
          {isEditor && (
            <DnDCalendar
              views={['day', 'agenda', 'work_week', 'month']}
              selectable
              localizer={localizer}
              defaultDate={new Date()}
              defaultView="month"
              events={eventsCalendar.data}
              onSelectEvent={handleMessage}
              onSelectSlot={handleSelect}
              onEventDrop={handleEventCalendar}
              onEventResize={handleEventCalendar}
              draggableAccessor={(event) => true}
              messages={t('calendar.set')}
            />
          )}
          {!isEditor && (
            <Calendar
              views={['day', 'agenda', 'work_week', 'month']}
              localizer={localizer}
              defaultDate={new Date()}
              defaultView="month"
              events={eventsCalendar.data}
              onSelectEvent={handleMessage}
              messages={t('calendar.set')}
            />
          )}
        </Flex>
      )}
    </>
  );
}
