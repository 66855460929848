import Icon from '../../../../components/Icon/Icon';
import { useEffect, useRef, useState } from 'react';
import { publish, subscribe, unsubscribe } from '../../../../utils/events';

export default function useTree({ handleSelect, dataTree }) {
  const [data, setData] = useState(dataTree || []);
  const [search, setSearch] = useState('');
  const treeRef = useRef(null);
  const [node, setNode] = useState(null);

  const handlerSaveTree = (e) => {
    const tree = treeRef.current;
    if (e.detail.data) node.data.data = JSON.stringify(e.detail.data);

    publish('SAVE_TREE_SENDED', { ...e.detail, data: tree.visibleNodes.map((item) => item.data) });
  };

  useEffect(() => {
    subscribe('SAVE_TREE', handlerSaveTree);

    return () => {
      unsubscribe('SAVE_TREE', handlerSaveTree);
    };
  }, [node]);

  const handleAddFolder = () => {
    treeRef.current.createInternal(treeRef.current.root.id);
  };

  const handleAddFile = async () => {
    treeRef.current.createLeaf();
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSetNode = (node) => {
    if (node[0]?.isLeaf) {
      setNode(node[0]);

      handleSelect({
        id: node[0].id,
        parent: node[0].parent.id,
        data: node[0].data.data,
        name: node[0].data.name,
      });
    }
  };

  const Node = ({ node, style, dragHandle, tree }) => {
    return (
      <div className={`node-container ${node.state.isSelected ? 'isSelected' : ''}`} style={style} ref={dragHandle}>
        <div className="node-content" onClick={() => node.isInternal && node.toggle()}>
          {node.isLeaf ? (
            <>
              <span className="arrow"></span>
              <span className="file-folder-icon">
                <Icon size={16} name={'File'} />
              </span>
            </>
          ) : (
            <span className="file-folder-icon">
              <Icon size={16} name={`${node.isOpen ? 'FolderOpen' : 'Folder'}`} />
            </span>
          )}
          <span className="node-text">
            {node.isEditing ? (
              <input
                type="text"
                defaultValue={node.data.name}
                onFocus={(e) => e.currentTarget.select()}
                onBlur={() => node.reset()}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') node.reset();
                  if (e.key === 'Enter') node.submit(e.currentTarget.value);
                }}
                autoFocus
              />
            ) : (
              <span>{node.data.name}</span>
            )}
          </span>
        </div>

        <div className="file-actions">
          <div className="folderFileActions">
            <button onClick={() => node.edit()} title="Rename...">
              <Icon name={'Pencil'} />
            </button>
            <button onClick={() => tree.delete(node.id)} title="Delete">
              <Icon name={'X'} />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return {
    data,
    handleAddFolder,
    handleAddFile,
    Node,
    treeRef,
    search,
    handleSearch,
    handleSetNode,
  };
}
