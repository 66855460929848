import classNames from 'classnames';
import './CheckBox.scss';

export default function CheckBox({ checked, handleCheckBox, disabled }) {
  const switchClass = classNames('switch', { disabled });

  return (
    <label className={switchClass}>
      <input checked={checked} onChange={handleCheckBox} type="checkbox" disabled={disabled} />
      <span className="slider"></span>
    </label>
  );
}
