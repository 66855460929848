import Flex from '../../components/Flex/Flex';
import { pdfjs, Document, Page, Outline } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import usePdf from './usePdf';
import './Pdf.scss';
import Nav from '../../components/Nav/Nav';
import Button from '../../components/Button/Button';
import Separator from '../../components/Separator/Separator';
import Set from '../Studio/components/settings/Set';
import DrawingCanvas from '../../components/DrawingCanvas/DrawingCanvas';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

export default function Pdf() {
  const {
    pageNumber,
    numPages,
    handleLoadSuccess,
    options,
    handleLoadProgress,
    handleShowSet,
    handlePublish,
    handleView,
    handleScroll,
    id,
    t,
    showSet,
    data,
    zoomIn,
    zoomOut,
    scale,
    drawings,
    setDrawings,
  } = usePdf();

  return (
    <>
      {showSet && <Set id={id} data={data} handleClose={handleShowSet} />}
      <Nav goTo="/" title={t('pdfPreview')}>
        <Flex>
          <Button handleClick={zoomOut} disable={scale <= 0.5} iconName={'ZoomOut'} />
          <span>{(scale * 100).toFixed(0)}%</span>
          <Button handleClick={zoomIn} iconName={'ZoomIn'} />
        </Flex>
        <Flex justifyEnd>
          <Button caption={t('preview')} handleClick={handleView} iconName={'Eye'} />
          <Button caption={t('settings')} handleClick={handleShowSet} iconName={'Settings'} />
          <Separator />
          <Button handleClick={handlePublish} iconName={'Rocket'} caption={t('publish')} />
          <Separator />
        </Flex>
      </Nav>
      <div onScroll={handleScroll} className="pdf">
        <Flex justifyCenter alignCenter>
          <Document
            file={`${process.env[`REACT_APP_API_URL_${process.env.REACT_APP_ENVIROMENT}`]}/storage/pdf/${id}`}
            onLoadSuccess={handleLoadSuccess}
            onLoadProgress={handleLoadProgress}
            options={options}
          >
            {Array.from(new Array(numPages), (_el, index) => (
              <div key={index} className="page-container">
                <Page pageNumber={index + 1} scale={scale} />
              </div>
            ))}
          </Document>
          <DrawingCanvas t={t} width={600} height={842} scale={scale} drawings={drawings} setDrawings={setDrawings} />
        </Flex>
      </div>
    </>
  );
}
