import Flex from '../Flex/Flex';
import './Brand.scss';
const BRAND = process.env.REACT_APP_TENANT;

export default function Brand() {
  return (
    <Flex className="brand" alignCenter>
      <img width={process.env.REACT_APP_TENANT_BRAND_SIZE || 50} src={`${BRAND}/assets/logo.png`} />
    </Flex>
  );
}
