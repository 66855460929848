import { useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/AuthProvider';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useTranslation } from 'react-i18next';

export default function useProfile({ handleCLose }) {
  const { logOut, user } = useAuth();
  const ref = useDetectClickOutside({ onTriggered: handleCLose });
  const { t } = useTranslation();

  const handleLogout = () => {
    logOut();
  };

  return { user, handleLogout, ref, t };
}
