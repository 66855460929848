import Flex from '../Flex/Flex';
import useTags from './useTags';
import './Tags.scss';
import Button from '../Button/Button';

export default function Tags({ t, dataTags, handleChange }) {
  const { ref, tag, tags, handleChangeTag, handleDeleteTag } = useTags({
    handleChange: handleChange,
    dataTags: dataTags,
  });
  return (
    <Flex className="tags" alignCenter>
      {tags.map((tag, index) => (
        <Flex key={`__t_${index}`} className="tag" alignCenter>
          <div>{tag}</div>
          <Button iconName={'X'} iconSize={14} handleClick={() => handleDeleteTag(index)} iconColor="#FFF" />
        </Flex>
      ))}

      <input ref={ref} placeholder={t('exercises.enterTag')} type="text" value={tag} onChange={handleChangeTag} />
    </Flex>
  );
}
