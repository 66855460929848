import ConfettiExplosion from 'react-confetti-explosion';
import './Quiz.scss';
import useQuiz from './useQuiz';
import Flex from '../../../components/Flex/Flex';
import Loader from '../../../components/Loader/Loader';
import Button from '../../../components/Button/Button';
import CoreButton from '../commons/CoreButton';

export default function Quiz({ data, handleSave, handleGetSaved, id, userResponse }) {
  const {
    quiz,
    handleSubmit,
    handleOptionChange,
    handleReset,
    currentQuestionIndex,
    showScore,
    selectedOption,
    score,
    t,
  } = useQuiz({
    data: data,
    handleSave: handleSave,
    handleGetSaved: handleGetSaved,
    id: id,
    userResponse: userResponse,
  });

  if (!quiz) return <Loader title="Loading" />;

  if (showScore) {
    return (
      <Flex column className="quiz-container">
        <h1 className="quiz-title">{quiz.title}</h1>

        <Flex alignCenter column className="score-container">
          <ConfettiExplosion force={0.4} particleCount={60} width={800} />
          <p>{t('exercises.completed')}.</p>
          <p className="score-text">{`${t('exercises.score')}${score}`}</p>
        </Flex>
        {/*<Button primary handleClick={handleReset} label={t('exercises.reset')} />*/}
      </Flex>
    );
  }

  const currentQuestion = quiz.questions[currentQuestionIndex];

  return (
    <Flex column className="quiz">
      <p className="title">{quiz.title}</p>
      <small>{quiz.description}</small>
      <Flex column>
        <p className="question">{currentQuestion?.text}</p>
        <Flex column>
          {currentQuestion?.options.map((option) => (
            <label key={option.id}>
              <input
                type="radio"
                value={option.id}
                checked={selectedOption === option.id}
                onChange={() => handleOptionChange(option.id)}
              />
              <span>{option.text}</span>
            </label>
          ))}
        </Flex>
        <Flex justifyEnd>
          <CoreButton handleClick={handleReset} bgColor={'#ebe7e7'}>
            {t('exercises.reset')}
          </CoreButton>
          <CoreButton color={'#FFF'} handleClick={handleSubmit}>
            {t('exercises.continue')}
          </CoreButton>
        </Flex>
      </Flex>
    </Flex>
  );
}
