import Button from '../../../../../components/Button/Button';
import Flex from '../../../../../components/Flex/Flex';
import Loader from '../../../../../components/Loader/Loader';
import './Quiz.scss';
import useQuiz from './useQuiz';

export default function Quiz({ data }) {
  const {
    quiz,
    handleSubmit,
    handleOptionChange,
    handleReset,
    currentQuestionIndex,
    showScore,
    selectedOption,
    score,
    t,
  } = useQuiz({
    data: data,
  });

  if (!quiz) return <Loader title="Loading" />;

  if (showScore) {
    return (
      <Flex column className="quiz-container">
        <h1 className="quiz-title">{quiz.title}</h1>
        <Flex column className="score-container">
          <p>{t('exercises.end')}.</p>
          <p className="score-text">{`${t('exercises.score')}: ${score} ${t('exercises.of')} ${
            quiz.questions.length
          }`}</p>
        </Flex>
        <Button primary handleClick={handleReset} label={t('exercises.reset')} />
      </Flex>
    );
  }

  const currentQuestion = quiz.questions[currentQuestionIndex];

  return (
    <Flex column className="quiz-container">
      <h1 className="quiz-title">{quiz.title}</h1>
      <h2 className="quiz-type">{`${t('exercises.type')}: ${quiz.type}`}</h2>
      <Flex column>
        <h2 className="question-text">{currentQuestion?.text}</h2>
        <Flex column className="options">
          {currentQuestion?.options.map((option) => (
            <label key={option.id} className="option-label">
              <input
                type="radio"
                name="option"
                value={option.id}
                checked={selectedOption === option.id}
                onChange={() => handleOptionChange(option.id)}
                className="option-input"
              />
              {option.text}
            </label>
          ))}
        </Flex>
        <Flex justifyEnd>
          <Button handleClick={handleReset} label={t('exercises.reset')} />
          <Button primary handleClick={handleSubmit} label={t('exercises.continue')} />
        </Flex>
      </Flex>
    </Flex>
  );
}
