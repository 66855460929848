import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActivitiesService from '../../services/Activities.service';
import useToast from '../../hooks/useToast';
import { useParams } from 'react-router-dom';

const DEFAULT_VALUE = [
  { id: String.fromCharCode(65), text: '' },
  { id: String.fromCharCode(66), text: '' },
];

export default function useQuizCreator({ data, onSave }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [quiz, setQuiz] = useState(data?.data?.questions || []);
  const [title, setTitle] = useState(data?.title || '');
  const [description, setDescription] = useState(data?.description || '');
  const [tags, setTags] = useState(data?.tag?.split(',') || []);
  const { toast, updateToast } = useToast();

  const handleAddQuestion = () => {
    setQuiz([
      ...quiz,
      {
        id: quiz.length + 1,
        text: '',
        options: JSON.parse(JSON.stringify(DEFAULT_VALUE)),
        correct_option: '',
      },
    ]);
  };

  useEffect(() => {
    if (!quiz.length) handleAddQuestion();
  }, [quiz]);

  const handleReset = () => {
    setQuiz([]);
    setTitle('');
    setDescription('');
    setTags([]);
  };

  const handleSaveQuiz = async (e) => {
    e.preventDefault();

    const payload = {
      data: {
        json: {
          title: title,
          description: description,
          type: 'multiple-choice',
          language: 'es',
          version: '1.0',
          questions: quiz,
        },
        tags: tags.toString(),
        templateId: id,
      },
    };

    const data = await ActivitiesService.post(payload);

    updateToast({ text: t('published'), background: toast.type.SUCCESS });
    handleReset();
    onSave && onSave(data);
  };

  const handleSetQuestion = ({ index, key, value }) => {
    const _quiz = [...quiz];
    _quiz[index][key] = value;
    setQuiz(_quiz);
  };

  const handleSetQuestionOption = ({ index, id, value }) => {
    const _quiz = [...quiz];

    _quiz[index].options.find((option) => option.id === id).text = value;
    setQuiz(_quiz);
  };

  const handleAddOption = ({ index }) => {
    const _quiz = [...quiz];
    _quiz[index].options.push({ id: String.fromCharCode(65 + _quiz[index].options.length), text: '' });
    setQuiz(_quiz);
  };

  const handleSort = (newState) => {
    setQuiz(newState);
  };

  const handleSortQuestion = ({ index, newState }) => {
    const _quiz = [...quiz];
    _quiz[index].options = newState;
    setQuiz(_quiz);
  };

  const handleTitleQuiz = (e) => {
    setTitle(e.target.value);
  };

  const handleCollapseQuestion = ({ index }) => {
    const _quiz = [...quiz];
    _quiz[index].collapse = !_quiz[index].collapse;
    setQuiz(_quiz);
  };

  const handleDeleteOption = ({ index, id }) => {
    const _quiz = [...quiz];
    _quiz[index].options.splice(id, 1);
    setQuiz(_quiz);
  };

  const handleDeleteQuestion = ({ index }) => {
    const _quiz = [...quiz];
    _quiz.splice(index, 1);
    setQuiz(_quiz);
  };

  const handleChangeTags = (_tags) => {
    setTags(_tags);
  };

  const handleDescriptionQuiz = (e) => {
    setDescription(e.target.value);
  };

  return {
    handleAddOption,
    handleAddQuestion,
    handleSaveQuiz,
    handleSetQuestion,
    handleSetQuestionOption,
    handleSort,
    handleSortQuestion,
    handleTitleQuiz,
    handleCollapseQuestion,
    handleDeleteOption,
    handleDeleteQuestion,
    handleChangeTags,
    handleReset,
    handleDescriptionQuiz,
    description,
    t,
    quiz,
    title,
    tags,
  };
}
