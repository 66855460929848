import { useEffect, useState } from 'react';

export default function useMyAvatar({ userConfig, handleChange }) {
  const [color, setColor] = useState('#F9C9B6');
  const [config, setConfig] = useState([
    {
      type: 'face',
      settings: {
        colors: { selected: 0, data: ['#F9C9B6', '#AC6651', '#675353'] },
      },
    },
    {
      type: 'ear',
      settings: {
        size: { selected: 0, data: ['small', 'big'] },
      },
    },
    {
      type: 'hair',
      settings: {
        colors: {
          selected: 0,
          data: [
            '#111',
            '#77311D',
            '#FC909F',
            '#D2EFF3',
            '#506AF4',
            '#F48150',
            '#ffffa4',
            '#d7d7d7',
            '#e34234',
            '#29291f',
            '#DCDCDC',
            '#836953',
          ],
        },
        style: { selected: 0, data: ['normal', 'thick', 'mohawk', 'womanLong', 'womanShort'] },
      },
    },
    {
      type: 'hat',
      settings: {
        colors: { selected: 0, data: ['#2e0716', '#c5ab75', '#c5ab75', '#b38b67'] },
        style: { selected: 0, data: ['beanie', 'turban', 'none'] },
      },
    },
    {
      type: 'eye',
      settings: {
        style: { selected: 0, data: ['circle', 'oval', 'smile'] },
      },
    },
    {
      type: 'glasses',
      settings: {
        style: { selected: 0, data: ['round', 'square', 'none'] },
      },
    },
    {
      type: 'nose',
      settings: {
        style: { selected: 0, data: ['short', 'long', 'round'] },
      },
    },
    {
      type: 'mouth',
      settings: {
        style: { selected: 0, data: ['laugh', 'smile', 'peace'] },
      },
    },
    {
      type: 'shirt',
      settings: {
        colors: { selected: 0, data: ['#2e0716', '#c5ab75', '#c5ab75', '#b38b67'] },
        style: { selected: 0, data: ['hoody', 'short', 'polo'] },
      },
    },
    {
      type: 'bg',
      settings: {
        colors: {
          selected: 0,
          data: [
            '#9287FF',
            '#6BD9E9',
            '#FC909F',
            '#F4D150',
            '#E0DDFF',
            '#D2EFF3',
            '#FFEDEF',
            '#FFEBA4',
            '#506AF4',
            '#F48150',
            '#74D153',
          ],
        },
      },
    },
  ]);
  const [uConfig, setUConfig] = useState(userConfig || {});

  useEffect(() => {
    if (!userConfig) {
      setUConfig({
        faceColor: '#F9C9B6',
        earSize: 'big',
        eyeStyle: 'oval',
        noseStyle: 'short',
        mouthStyle: 'laugh',
        shirtStyle: 'short',
        glassesStyle: 'square',
        hairColor: '#000',
        hairStyle: 'mohawk',
        hatStyle: 'none',
        hatColor: '#F48150',
        eyeBrowStyle: 'up',
        shirtColor: '#9287FF',
        bgColor: '#FC909F',
      });
    }
  }, []);

  const updateUserConfig = ({ key, value }) => {
    console.log(key);
    const _uConfig = { ...uConfig };
    _uConfig[key] = value;
    setUConfig(_uConfig);
    handleChange(_uConfig);
  };

  const updateSelected = ({ element, key }) => {
    const el = element.settings[key];
    el.selected = (el.selected + 1) % el.data.length;
    return element;
  };

  const handleClick = ({ type, bg }) => {
    const _config = [...config];
    let element = _config.find((item) => item.type === type);

    if (bg || type.match(/face|bg/)) {
      element = updateSelected({ element: element, key: 'colors' });
      const color = element.settings.colors.data[element.settings.colors.selected];
      updateUserConfig({ key: `${type}Color`, value: color });
      if (type === 'face') setColor(color);
    } else {
      const key = element.settings.size ? 'size' : 'style';
      element = updateSelected({ element: element, key: key });
      const value = element.settings[key].data[element.settings[key].selected];
      updateUserConfig({ key: `${type}${key.replace(/\b[a-z]/g, (x) => x.toUpperCase())}`, value: value });
    }
    setConfig(_config);
  };

  return {
    config,
    handleClick,
    color,
    uConfig,
  };
}
