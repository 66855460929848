import Flex from '../../../components/Flex/Flex';
import { CalendarClock } from 'lucide-react';

export default function CalendarManager({ calendars, handleSelectCalendar }) {
  return (
    <Flex className="calendars">
      {calendars?.map((item, index) => (
        <Flex
          key={`__m_${index}`}
          title={item.label}
          className={`mini-card --active-${item.active}`}
          column
          alignCenter
          handleClick={() => handleSelectCalendar(item)}
        >
          <CalendarClock size={36} />
          <span>{item.label}</span>
        </Flex>
      ))}
    </Flex>
  );
}
