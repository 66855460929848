import { Route, Routes, HashRouter } from 'react-router-dom';

import Login from './pages/Login/Login';
import Toast from './components/toast/Toast';
import PrivateRoutes from './utils/PrivateRoutes';
import Flex from './components/Flex/Flex';
import Logo from './assets/logo.png';
import AuthProvider from './hooks/AuthProvider';
import Dash from './pages/Dash/Dash';
import { RemoveTrailingSlash } from './utils/RemoveTrailingSlash';
import Register from './pages/Register/Register';
import Studio from './pages/Studio/Studio';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import Licenses from './pages/Licenses/Licenses';
import QuizCreator from './pages/QuizCreator/QuizCreator';
import Activities from './pages/Activities/Activities';
import Create from './pages/Activities/components/Create';
import Classes from './pages/Classes/Classes';
import Users from './pages/Users/Users';
import Account from './pages/Account/Account';
import BigCalendar from './pages/Calendar/BigCalendar';
import Resources from './pages/Resources/Resources';
import Gradebook from './pages/Gradebook/Gradebook';
import { useEffect } from 'react';
import { useTenantMeta } from './hooks/useTenantMeta';
import ProtectedRoutes from './utils/ProtectedRoutes';
import Pdf from './pages/Pdf/Pdf';
import Templates from './components/Templates/Templates';
const tenant = process.env.REACT_APP_TENANT;

export default function App() {
  useTenantMeta(tenant);

  useEffect(() => {
    const appleLinkIcon = document.querySelector("link[rel='apple-touch-icon']");
    if (appleLinkIcon) {
      appleLinkIcon.href = appleLinkIcon.href.replace('tenant', tenant);
    }
    const links = document.querySelectorAll("link[rel='icon']");
    links.forEach((link) => {
      link.href = link.href.replace('tenant', tenant);
    });
  }, []);

  const NotFound = () => {
    return (
      <Flex column alignCenter className="not-found">
        <img src={Logo} alt="brain" width={140} />
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you are looking for could not be found.</p>
      </Flex>
    );
  };
  return (
    <I18nextProvider i18n={i18n}>
      <HashRouter>
        <RemoveTrailingSlash />
        <Toast />
        <AuthProvider>
          <Routes>
            <Route exact path="/auth/login" element={<Login />} />
            <Route exact path="/auth/register" element={<Register />} />
            <Route element={<PrivateRoutes />}>
              <Route path="/" element={<Dash />} />
              <Route element={<ProtectedRoutes />}>
                <Route path="/classes" element={<Classes />} />
                <Route path="/studio/:guid?" element={<Studio />} />
                <Route path="/pdf/:id?" element={<Pdf />} />
                <Route path="/licenses" element={<Licenses />} />
                <Route path="/quiz" element={<QuizCreator />} />
                <Route path="/activities" element={<Activities />} />
                <Route path="/activities/templates" element={<Templates />} />
                <Route path="/activities/template/:id/:activityId?" element={<Create />} />
                <Route path="/activities/new" element={<Create />} />
                <Route path="/users" element={<Users />} />
                <Route path="/resources" element={<Resources />} />
              </Route>

              <Route path="/account" element={<Account />} />
              <Route path="/calendar" element={<BigCalendar />} />
              <Route path="/gradebook" element={<Gradebook />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </HashRouter>
    </I18nextProvider>
  );
}
