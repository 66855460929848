import axios from './Client';

const all = async () => {
  const response = await axios.get(`/activities`);
  return response.data;
};

const byGuid = async ({ guid }) => {
  const response = await axios.get(`/activities/${guid}`);
  return response.data;
};

const post = async ({ data }) => {
  const response = await axios.post(`/activities`, data);
  return response.data;
};

const history = async ({ data, id }) => {
  const response = await axios.post(`/contents/${id}`, data);
  return response.data;
};

export default { all, post, byGuid, history };
