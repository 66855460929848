import React, { useRef, useEffect, useState } from 'react';
import Flex from '../Flex/Flex';
import Button from '../Button/Button';
import './DrawingCanvas.scss';

const DrawingCanvas = ({ width, height, scale = 1, drawings = [], setDrawings, t }) => {
  const canvasRef = useRef(null);
  const ghostCanvasRef = useRef(null); // Referencia para el ghost canvas
  const isDrawing = useRef(false);
  const [tool, setTool] = useState('pencil'); // Herramienta activa
  const [color, setColor] = useState('#000000'); // Color del lápiz
  const [lineWidth, setLineWidth] = useState(3); // Grosor del lápiz/borrador
  const [points, setPoints] = useState([]); // Almacena todos los puntos para el trazo libre

  // Iniciar dibujo
  const startDrawing = (event) => {
    isDrawing.current = true;
    const { x, y } = getEventCoordinates(event);
    setPoints([{ x, y }]); // Iniciar el array de puntos con el primer punto
  };

  // Dibujar en el ghost canvas a mano alzada
  const drawOnGhostCanvas = (newPoints) => {
    const ghostCanvas = ghostCanvasRef.current;
    const ctx = ghostCanvas.getContext('2d');
    ctx.clearRect(0, 0, ghostCanvas.width, ghostCanvas.height); // Limpiar el ghost canvas
    ctx.lineWidth = lineWidth;
    ctx.lineCap = 'round';

    if (tool === 'eraser') {
      ctx.strokeStyle = '#FFF';
      ctx.lineWidth = 20;
    } else {
      ctx.strokeStyle = color;
      ctx.globalCompositeOperation = 'source-over'; // Comportamiento normal
    }

    ctx.beginPath();
    ctx.moveTo(newPoints[0].x * scale, newPoints[0].y * scale); // Iniciar en el primer punto

    for (let i = 1; i < newPoints.length; i++) {
      ctx.lineTo(newPoints[i].x * scale, newPoints[i].y * scale); // Dibujar líneas suaves entre puntos
    }
    ctx.stroke();
  };

  // Continuar dibujando a mano alzada
  const draw = (event) => {
    if (!isDrawing.current) return;
    const { x, y } = getEventCoordinates(event);
    setPoints((prevPoints) => {
      const newPoints = [...prevPoints, { x, y }]; // Agregar el nuevo punto al array de puntos
      drawOnGhostCanvas(newPoints); // Dibujar todos los puntos en el ghost canvas
      return newPoints;
    });
  };

  // Terminar dibujo y consolidar en el canvas principal
  const stopDrawing = () => {
    if (!isDrawing.current) return;
    isDrawing.current = false;

    // Guardar el trazo en el array de drawings
    setDrawings((prevDrawings) => [...prevDrawings, { points, tool, color, lineWidth }]);

    // Limpiar el ghost canvas al terminar
    const ghostCanvas = ghostCanvasRef.current;
    const ctx = ghostCanvas.getContext('2d');
    ctx.clearRect(0, 0, ghostCanvas.width, ghostCanvas.height);
  };

  // Obtener coordenadas del evento para mouse/touch
  const getEventCoordinates = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const clientX = event.clientX || event.touches[0].clientX;
    const clientY = event.clientY || event.touches[0].clientY;
    const x = (clientX - rect.left) / scale;
    const y = (clientY - rect.top) / scale;
    return { x, y };
  };

  // Redibujar el canvas principal
  const redrawCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    drawings.forEach((drawing) => {
      const { points, tool, color, lineWidth } = drawing;
      ctx.lineWidth = lineWidth;
      ctx.lineCap = 'round';
      if (tool === 'eraser') {
        ctx.globalCompositeOperation = 'destination-out';
        ctx.lineWidth = 20;
      } else {
        ctx.strokeStyle = color;
        ctx.globalCompositeOperation = 'source-over'; // Comportamiento normal
      }

      ctx.beginPath();
      ctx.moveTo(points[0].x * scale, points[0].y * scale); // Iniciar en el primer punto

      for (let i = 1; i < points.length; i++) {
        ctx.lineTo(points[i].x * scale, points[i].y * scale); // Dibujar líneas suaves entre puntos
      }
      ctx.stroke();
    });
  };

  useEffect(() => {
    redrawCanvas();
  }, [drawings, scale]);

  const resetCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const ghostCanvas = ghostCanvasRef.current;
    const ghostCtx = ghostCanvas.getContext('2d');
    ghostCtx.clearRect(0, 0, ghostCanvas.width, ghostCanvas.height);

    setDrawings([]);
  };

  return (
    <div className="drawing-canvas">
      <Flex className="tool">
        <Flex column>
          <Button
            active={tool === 'pencil'}
            caption={t('drawing.pencil')}
            handleClick={() => setTool('pencil')}
            iconName={'Pencil'}
          />
          <input type="range" min="1" max="10" value={lineWidth} onChange={(e) => setLineWidth(e.target.value)} />
        </Flex>

        <Button
          active={tool === 'eraser'}
          caption={t('drawing.eraser')}
          handleClick={() => setTool('eraser')}
          iconName={'Eraser'}
        />
        <Button caption={t('drawing.clear')} handleClick={resetCanvas} iconName={'RotateCcw'} />
        <input type="color" value={color} onChange={(e) => setColor(e.target.value)} />
        <Button caption={t('drawing.save')} handleClick={() => {}} iconName={'Save'} />
        <Button caption={t('drawing.close')} handleClick={() => {}} iconName={'X'} />
      </Flex>
      <canvas ref={ghostCanvasRef} width={width * scale} height={height * scale} className="ghost" />
      <canvas
        ref={canvasRef}
        width={width * scale}
        height={height * scale}
        className="main"
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseLeave={stopDrawing}
        onTouchStart={startDrawing}
        onTouchMove={draw}
        onTouchEnd={stopDrawing}
      />
    </div>
  );
};

export default DrawingCanvas;
