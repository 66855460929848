import moment from 'moment';
import { momentLocalizer } from 'react-big-calendar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'moment/locale/es';
import CalendarsService from '../../services/Calendars.service';
import { useAuth } from '../../hooks/AuthProvider';
import UsersService from '../../services/Users.service';
import useToast from '../../hooks/useToast';
moment.locale('es-ES');
const localizer = momentLocalizer(moment);

export default function useBigCalendar() {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { toast, updateToast } = useToast();
  const [eventsCalendar, setEventsCalendar] = useState(null);
  const [calendars, setCalendars] = useState(null);
  const [showCreate, setShowCreate] = useState(false);
  const [showMessage, setShowMessage] = useState({ show: false, data: null });
  const [name, setName] = useState('');
  const [newDate, setNewDate] = useState(null);

  const getCalendar = async () => {
    const data = await CalendarsService.get();
    const newResponse = data.map((obj) => ({
      value: obj.id,
      label: obj.title,
    }));
    setCalendars(newResponse);
  };

  const getEventsCalendarByStudent = async () => {
    const data = await UsersService.getByStudent();
    const newResponse = data.map((obj, index) => ({
      value: obj.id,
      label: obj.name,
      active: false,
    }));
    setCalendars(newResponse);
  };

  useEffect(() => {
    if (user?.userType !== 'student') getCalendar();
    else getEventsCalendarByStudent();
  }, []);

  const handleCloseCreate = () => {
    setShowCreate(false);
  };

  const handleSelectCalendar = async (data) => {
    if (data.active) return;
    const _calendar = [...calendars];
    _calendar.map((item) => (item.active = false));
    setCalendars(_calendar.map((item) => (item.value === data.value ? { ...item, active: !item.active } : item)));

    const response =
      user?.userType !== 'student'
        ? await CalendarsService.getById({ id: data.value })
        : await CalendarsService.getEventsCalendarByClassId({ id: data.value });

    const newResponse = response?.CalendarEvents?.map((obj) => ({
      start: new Date(obj.event.start),
      end: new Date(obj.event.end),
      title: obj.event.title,
      description: obj.event.description,
      id: obj.id,
    }));
    setEventsCalendar({
      id: data.value,
      data: newResponse,
    });
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleSubmitCalendar = async (e) => {
    e.preventDefault();
    const data = await CalendarsService.postCalendar({ data: { title: name } });
    const { id, title } = data;
    setCalendars([...calendars, { value: id, label: title }]);
    setName('');
  };

  const handleSelect = ({ start, end }) => {
    setNewDate({ start: start, end: end });
    setShowCreate(true);
  };

  const handleEventCalendar = async (data) => {
    const events = { ...eventsCalendar };
    const evt = events.data.find((item) => item.id === data.event.id);
    evt.start = data.start;
    evt.end = data.end;
    const cloudEvt = { ...evt };
    delete cloudEvt.id;
    await CalendarsService.putEvent({ data: { event: cloudEvt }, id: data.event.id });
    updateToast({ text: t('cloudSave'), background: toast.type.SUCCESS });
    setEventsCalendar(events);
  };

  const handleFinishEvent = (data) => {
    setEventsCalendar((prevState) => ({
      ...prevState,
      data: [...prevState.data, { ...data.event, id: data.id }],
    }));

    handleCloseCreate();
  };

  const handleMessage = (event) => {
    setShowMessage({ ...showMessage, show: !showMessage.show, data: event });
  };

  const handleDeleteEvent = async () => {
    try {
      await CalendarsService.delEvent({ id: showMessage.data.id });
      setEventsCalendar((prevState) => ({
        ...prevState,
        data: prevState.data.filter((item) => item.id !== showMessage.data.id),
      }));
      setShowMessage({ ...showMessage, show: !showMessage.show, data: null });
    } catch (error) {
      updateToast({ text: 'Ups hubo un problema' });
    }
  };

  return {
    t,
    eventsCalendar,
    localizer,
    handleSelect,
    handleCloseCreate,
    showCreate,
    showMessage,
    calendars,
    name,
    handleName,
    handleSubmitCalendar,
    handleSelectCalendar,
    handleEventCalendar,
    handleFinishEvent,
    handleMessage,
    handleDeleteEvent,
    newDate,
    isEditor: user?.userType !== 'student',
  };
}
