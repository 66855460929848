import { useEffect, useState } from 'react';
import TypesService from '../../../../services/Types.service';
import ContentsService from '../../../../services/Contents.service';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useToast from '../../../../hooks/useToast';

export default function useSet({ data, handleClose, id }) {
  const navigate = useNavigate();
  const [title, setTitle] = useState(data?.title || data?.content?.title || '');
  const [description, setDescription] = useState(data?.description || data?.content?.description || '');
  const [disable, setDisable] = useState(false);
  const [options, setOptions] = useState(null);
  const [option, setOption] = useState('');
  const [image, setImage] = useState(null);
  const [pdf, setPdf] = useState(null);
  const { t } = useTranslation();
  const { toast, updateToast } = useToast();

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await TypesService.all();
        setOptions(data.types);
        setOption(data.types[0].value);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);

    if (data) {
      await ContentsService.put({ data: { title: title, description: description }, id: data.id });
      updateToast({ text: t('contentUpdated'), background: toast.type.SUCCESS });

      handleClose();
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('typeId', option);
    formData.append('image', image);
    formData.append('pdf', pdf);

    await ContentsService.create({ data: formData });

    updateToast({ text: t('contentAdded'), background: toast.type.SUCCESS });
    navigate('/');
  };

  const handleSelect = (e) => {
    setOption(e.value);
  };

  const handleImage = async (event) => {
    const file = event.target.files;
    setImage(file?.[0]);
  };

  const handlePdf = async (event) => {
    const file = event.target.files;
    setPdf(file?.[0]);
  };

  const handleDeleteContent = async () => {
    await ContentsService.del({ id: id });
    updateToast({ text: t('contentDeleted'), background: toast.type.INFO });
    navigate('/');
  };

  return {
    handleTitleChange,
    title,
    handleDescriptionChange,
    description,
    handleSubmit,
    disable,
    handleSelect,
    options,
    option,
    handleImage,
    handlePdf,
    handleDeleteContent,
    t,
  };
}
