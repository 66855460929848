import { getMainMenu } from '../../../utils/Utils';
import Separator from '../../../components/Separator/Separator';
import Button from '../../../components/Button/Button';
import { Link } from 'react-router-dom';
import Flex from '../../../components/Flex/Flex';
import { ArrowRight } from 'lucide-react';

export default function Menu({ userMenu, haveContent, t, handleDecision, isEditor }) {
  const MAIN_MENU = getMainMenu({ t: t, isEditor: isEditor });

  return MAIN_MENU.map((item, index) => {
    return (
      <Flex key={`__menu_${index}`}>
        {item.separator && <Separator />}
        {item.help?.caption && (
          <Flex alignCenter hide={!!haveContent}>
            <small className="tips">{item.caption}</small>
            <ArrowRight color="var(--color-note)" className="anim" size={16} />
          </Flex>
        )}
        {item.linkTo && userMenu.includes(item.linkTo) && (
          <Link to={`/${item.linkTo}${item.path || ''}`}>
            <Button
              disable={(item.preventClick && !haveContent) || false}
              caption={item.caption}
              iconName={item.icon}
            />
          </Link>
        )}
        {item.name && userMenu.includes(item.name) && (
          <Button name={item.name} caption={item.caption} handleClick={handleDecision} iconName={item.icon} />
        )}
      </Flex>
    );
  });
}
