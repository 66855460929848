import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/AuthProvider';
import StorageService from '../../../../services/Storage.service';
import Brain from '../../../../assets/logo_card.png';
import { useTranslation } from 'react-i18next';

const environment = process.env.REACT_APP_ENVIROMENT;
const VIEWER = process.env[`REACT_APP_VIEWER_URL_${environment}`];

export default function useArticle({ item, isEditor }) {
  const auth = useAuth();
  const [image, setImage] = useState(Brain);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const data = await StorageService.getById({ id: item.id });
      setImage(`data:image/png;base64,${data.image}`);
    };
    fetch();
  }, []);

  const handleView = (e) => {
    e.preventDefault();

    if (item.PdfStorage?.path) {
      if (!isEditor) window.open(`${VIEWER}/?viewer=pdf&post=${item.id}&token=${auth.token}`, '_blank');
      else navigate(`/pdf/${item.id}`);
      return;
    }
    if (!isEditor) {
      window.open(`${VIEWER}/?viewer=html&post=${item.Publications[0].id}&token=${auth.token}`, '_blank');
      return;
    }

    navigate(`/studio/${item.id}`);
  };

  return { image, t, handleView };
}
