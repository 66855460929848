import axios from './Client';

const reedem = async ({ data }) => {
  const response = await axios.post(`/licenses/redeem`, data);
  return response.data;
};

const get = async () => {
  const response = await axios.get(`/licenses`);
  return response.data;
};

const post = async ({ data }) => {
  const response = await axios.post(`/licenses`, data);
  return response.data;
};

const put = async ({ data, id }) => {
  const response = await axios.put(`/licenses/${id}`, data);
  return response.data;
};

export default { get, post, put, reedem };
