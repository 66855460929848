export const en = {
  login: {
    signIn: 'Sign in',
    signUp: 'Sign up',
    speech: 'Welcome to Brain, the educational revolution powered by artificial intelligence.',
    email: 'E-mail',
    password: 'Password',
    or: 'or',
    here: 'here',
    signOut: 'Sign out',
    account: 'Manage account',
    myProfile: 'My profile',
    init: 'Iniciar sesión',
  },
  a: 'a',
  dni: 'DNI',
  birth: 'Birth date',
  content: 'Content',
  contentSettings: 'Content settings',
  please: 'Please, complete the fields below.',
  title: 'Title',
  description: 'Description',
  image: 'Image',
  contentType: 'Content type',
  pdfFile: 'Upload pdf file',
  save: 'Save',
  cloudSave: 'Saved to cloud',
  savedLocal: 'Saved locally',
  saveAll: 'Save all',
  settings: 'Settings',
  publish: 'Publish',
  published: 'Published',
  publications: 'Publications',
  writeHere: "Let's write an awesome story",
  preview: 'Preview',
  enableAutosave: 'Enable autosave',
  generate: 'Generate licenses',
  contentCreator: 'Content creator',
  deleteContent: 'Delete content',
  contentDeleted: 'Content deleted',
  profile: 'Profile',
  noLicense: "You don't have a license.",
  license: 'License',
  published: 'Published',
  code: 'Code',
  qty: 'Uses quantity',
  users: 'Users',
  classes: 'Classes',
  expiresAt: 'Expire at',
  actions: 'Actions',
  action: 'Action',
  newLicense: 'New license',
  newClass: 'New class',
  newUser: 'New user',
  cancel: 'Cancel',
  dateFormat: 'MM-DD-YYYY',
  durations: [
    { value: 'days', text: 'Days' },
    { value: 'months', text: 'Months' },
    { value: 'years', text: 'Years' },
  ],
  dataSheet: 'Data sheet',
  selectAtLeast: 'Select at least one publication',
  addLicense: 'Add license',
  please: 'Please insert the license code below.',
  activity: 'Activity',
  activities: 'Activities',
  templates: 'Templates',
  tags: 'Tags',
  totalRecords: 'Number of records',
  search: 'Search',
  students: 'Students',
  contentAdded: 'Content added with success',
  contentUpdated: 'Content updated',
  or: 'or in a button from header panel.',
  here: 'here',
  for: 'for activate license',
  noRecords: 'There are no records',
  noContents: 'Redeem a license to obtain your content!',
  name: 'Name',
  surname: 'Surname',
  email: 'E-mail',
  color: 'Color',
  resources: 'Resources',
  tips: {
    newContent: 'Click the book icon to create content',
    newFile: 'Click the file icon to start',
    newLicense: 'Click the license icon to reedem one',
  },
  templateQuiz: {
    name: 'Quiz Creator',
    addQuestion: 'Add question',
    save: 'Save quiz',
    question: 'Enter your question',
    option: 'Enter an option',
    addOption: 'Add option',
    options: 'Options',
    select: 'Select the correct answer',
    title: 'Enter the title for quiz',
  },
  exercises: {
    continue: 'Continue',
    reset: 'Reset',
    end: 'You have completed the activity',
    score: 'Your score is',
    of: 'of',
    type: 'Type',
    enterTag: 'Enter tag',
    addTag: 'Add tag',
  },
  errors: {
    email: 'Email already in use',
  },
  calendar: {
    title: 'School calendar',
    set: {
      next: 'Next',
      previous: 'Prev',
      today: 'Today',
      month: 'Month',
      week: 'Week',
      day: 'Day',
    },
    event: 'New event',
  },
};
