import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PublicationsService from '../../services/Publications.service';
import { base64ToUtf8 } from '../../utils/Utils';

export default function useGradebook() {
  const { t } = useTranslation();
  const [publications, setPublications] = useState(null);
  const [dataTable, setDataTable] = useState(null);
  const [quiz, setQuiz] = useState(null);
  const [buttons, setButtons] = useState([
    {
      action: { view: true },
      caption: t('preview'),
      iconName: 'Eye',
      iconSize: 20,
    },
  ]);

  useEffect(() => {
    const fetch = async () => {
      const data = await PublicationsService.getAllForUser();
      setPublications(data?.map((item) => ({ ...item, value: item.id, label: item.title })));
    };

    fetch();
  }, []);

  const handleSelectPublication = async (sel) => {
    const PublicationId = publications.find((item) => item.id == sel.value).Publications[0].id;
    const data = await PublicationsService.getActivitiesFromPublications({ id: PublicationId });
    data.forEach((item) => {
      item.Activity.data = JSON.parse(base64ToUtf8(item.Activity.data));
    });
    setDataTable({
      type: sel.label,
      header: [t('activity'), t('exercises.score2'), t('exercises.lastAccess'), t('actions')],
      attributes: ['Activity.data.title', 'Users.UserActivity.score', 'Users.UserActivity.createdAt', ''],
      body: data,
    });
  };

  const handleDecision = ({ id }) => {
    const data = dataTable.body.find((it) => it.id === id);
    setQuiz(data);
  };

  const handleGetSaved = () => {
    return quiz.Users?.UserActivity || null;
  };

  return { t, publications, handleSelectPublication, handleDecision, dataTable, buttons, quiz, handleGetSaved };
}
