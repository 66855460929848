import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function useQuiz({ data }) {
  const [quiz, setQuiz] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const { t } = useTranslation();

  const handleOptionChange = (optionId) => {
    setSelectedOption(optionId);
  };

  useEffect(() => {
    setQuiz(data);
  }, [data]);

  const handleSubmit = () => {
    if (quiz) {
      const currentQuestion = quiz.questions[currentQuestionIndex];
      if (selectedOption === currentQuestion.correct_option) {
        setScore(score + 1);
      }
      const nextQuestionIndex = currentQuestionIndex + 1;
      if (nextQuestionIndex < quiz.questions.length) {
        setCurrentQuestionIndex(nextQuestionIndex);
        setSelectedOption(null);
      } else {
        setShowScore(true);
      }
    }
  };

  const handleReset = () => {
    setShowScore(false);
    setScore(0);
    setCurrentQuestionIndex(0);
    setSelectedOption(null);
  };

  return {
    handleOptionChange,
    handleSubmit,
    handleReset,
    quiz,
    currentQuestionIndex,
    showScore,
    selectedOption,
    score,
    t,
  };
}
