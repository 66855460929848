import axios from './Client';

const all = async () => {
  const response = await axios.get(`/contents`);
  return response.data;
};

const byGuid = async ({ guid }) => {
  const response = await axios.get(`/contents/${guid}`);
  return response.data;
};

const put = async ({ data, id }) => {
  const response = await axios.put(`/contents/${id}`, data);
  return response.data;
};

const del = async ({ id }) => {
  const response = await axios.delete(`/contents/${id}`);
  return response.data;
};

const create = async ({ data }) => {
  const response = await axios.post(`/contents`, data);
  return response.data;
};

const history = async ({ data, id }) => {
  const response = await axios.post(`/contents/${id}`, data);
  return response.data;
};

export default { all, create, byGuid, history, put, del };
