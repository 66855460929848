import React from 'react';
import classNames from 'classnames';
import useToast from './useToast';
import './Toast.scss';

export default function Toast() {
  const { text, show, toast } = useToast();

  const toastClass = classNames('toast', {
    [`--${toast.background}`]: toast.background,
    '--t-true': show,
  });

  return <div className={toastClass}>{text}</div>;
}
