import Flex from '../../components/Flex/Flex';
import MyAvatar from '../../components/Avatar/MyAvatar';
import useAccount from './useAccount';
import Button from '../../components/Button/Button';
import './Account.scss';
import Nav from '../../components/Nav/Nav';

export default function Account() {
  const { handleSubmit, handleUser, handleChangeAvatar, data, t, ref } = useAccount();

  return (
    <Flex className="account-wrapper" column>
      <Nav title={t('login.account')} />
      <Flex justifyCenter className="form account">
        <form onSubmit={handleSubmit}>
          <Flex column className="item simple" gap={'20px'}>
            <h3>{t('login.myProfile')}</h3>
            <small>{t('dataSheet')}</small>
            <Flex className="avatar-wrapper" justifyCenter>
              <MyAvatar edit handleChange={handleChangeAvatar} userConfig={data.avatar} />
            </Flex>
            <div>
              <label>{t('name')}</label>
              <input ref={ref} type="text" required name="name" value={data.name} onChange={handleUser} />
            </div>
            <div>
              <label>{t('login.password')}</label>
              <input type="password" name="password" value={data.password} onChange={handleUser} />
            </div>
            <div>
              <label>{t('login.newPassword')}</label>
              <input maxLength={12} type="password" name="newPassword" value={data.newPassword} onChange={handleUser} />
            </div>
            <Flex justifyEnd gap={'10px'}>
              <Button primary type="submit" label={t('save')} />
            </Flex>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
}
