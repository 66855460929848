import React, { useState, useRef, useEffect } from 'react';
import './Tooltip.scss';

function Tooltip({ content, children }) {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState('top');
  const tooltipRef = useRef(null);
  const containerRef = useRef(null);

  const showTooltip = () => {
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (visible) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      const spaceAbove = containerRect.top;
      const spaceBelow = window.innerHeight - containerRect.bottom;
      const spaceLeft = containerRect.left;
      const spaceRight = window.innerWidth - containerRect.right;

      if (spaceAbove > tooltipRect.height) {
        setPosition('top');
      } else if (spaceBelow > tooltipRect.height) {
        setPosition('bottom');
      } else if (spaceLeft > tooltipRect.width) {
        setPosition('left');
      } else if (spaceRight > tooltipRect.width) {
        setPosition('right');
      } else {
        setPosition('top'); // Posición por defecto
      }
    }
  }, [visible]);

  return (
    <div className="tooltip-container" ref={containerRef} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      {visible && (
        <div className={`tooltip tooltip-${position}`} ref={tooltipRef}>
          {content}
        </div>
      )}
    </div>
  );
}

export default Tooltip;
