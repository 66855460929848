import React from 'react';
import classNames from 'classnames';
import Flex from '../Flex/Flex';
import Button from '../Button/Button';
import './Number.scss';

export default function NumberInput({ value = 1, onChange, disabled }) {
  const handleIncrement = () => {
    onChange(value + 1);
  };

  const handleDecrement = () => {
    if (value > 1) {
      onChange(value - 1);
    }
  };

  const containerClass = classNames('number-input', {
    disabled: disabled,
  });

  return (
    <Flex disabled={disabled} gap="3px" alignCenter className={containerClass}>
      <Button iconColor="#FFF" handleClick={handleDecrement} iconName="Minus" />
      <input value={value} onChange={(e) => onChange(parseInt(e.target.value, 10))} type="text" disabled={disabled} />
      <Button iconColor="#FFF" handleClick={handleIncrement} iconName="Plus" />
    </Flex>
  );
}
