import useTree from './useTree';
import './Tree.scss';
import Flex from '../../../../components/Flex/Flex';
import Button from '../../../../components/Button/Button';
import { Tree } from 'react-arborist';
import { ArrowLeft } from 'lucide-react';

export default function Trees({ show, handleSelect, dataTree, t }) {
  const { data, Node, treeRef, handleAddFolder, handleAddFile, search, handleSearch, handleSetNode } = useTree({
    handleSelect: handleSelect,
    dataTree: dataTree,
  });

  return (
    <>
      <Flex className={`--sidebar --${show}`} column gap={'15px'}>
        <Flex className="border">
          <Button caption="Add" handleClick={handleAddFolder} iconName={'FolderPlus'} disable={true} />
          <Flex>
            <Button caption="Add" handleClick={handleAddFile} iconName={'FilePlus2'} />
            <Flex alignCenter hide={!!data.length}>
              <ArrowLeft color="var(--color-note)" className="anim_left" size={16} />
              <small className="tips">{t('tips.newFile')}</small>
            </Flex>
          </Flex>
        </Flex>
        <Flex>
          <input type="text" className="search-input" placeholder="Search..." value={search} onChange={handleSearch} />
        </Flex>
        {data && (
          <Tree
            width={'auto'}
            ref={treeRef}
            initialData={data}
            rowHeight={30}
            searchTerm={search}
            searchMatch={(node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())}
            onSelect={handleSetNode}
          >
            {Node}
          </Tree>
        )}
      </Flex>
    </>
  );
}
