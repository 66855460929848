import React, { useState } from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import CheckBox from '../Checkbox/CheckBox';
import Flex from '../Flex/Flex';
import './Table.scss';
import { OctagonAlert, TriangleAlert } from 'lucide-react';
import moment from 'moment-timezone';

export default function Table({ data, handleDecision, t, forcePlus = false, buttons = [], closable = false }) {
  const [search, setSearch] = useState('');
  const { control } = data;

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const Header = () => (
    <thead>
      <tr>
        {data.header.map((item, index) => (
          <th key={`_i_${index}`}>{item}</th>
        ))}
      </tr>
    </thead>
  );

  const isNumericAndNotDate = (value) => {
    const isNumber = typeof value === 'number' && !isNaN(value);
    const isDate = value instanceof Date && !isNaN(value.valueOf());
    return isNumber && !isDate;
  };

  const dataType = ({ value, data }) => {
    const _value = value.includes('.') ? getValueByString(data, value) : data[value];
    const isDate = moment(_value, true).isValid();

    if (isNumericAndNotDate(_value)) return _value;
    if (typeof _value === 'undefined') return '-';
    if (typeof _value === 'boolean') return <CheckBox isChecked={!_value} />;
    if (Array.isArray(_value)) return _value.map((item) => item.name).join(', ');
    if (isDate) {
      if (control && control === value) {
        const dateBd = moment(_value);
        const today = moment();
        if (!dateBd.isSame(today, 'day') && dateBd.isBefore(today)) {
          return (
            <Flex alignCenter>
              <TriangleAlert color="var(--color-primary)" size={20} />
              {moment.tz(_value, 'UTC').format(t('dateFormat'))}
            </Flex>
          );
        }
      }
      return moment.tz(_value, 'UTC').format(t('dateFormat'));
    }
    if (_value?.toString().includes('#')) return <div className="color" style={{ background: _value }} />;
    return _value;
  };

  const actions = ({ id }) => (
    <Flex>
      {buttons.map((item, index) => (
        <Button
          caption={item.caption || ''}
          key={`__${index}_`}
          handleClick={() => handleDecision({ ...item.action, id })}
          iconName={item.iconName}
          iconSize={item.iconSize}
        />
      ))}
    </Flex>
  );

  const getValueByString = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const Body = () => (
    <tbody>
      {data?.body.map((body, index) => (
        <tr key={`_tr_${index}`}>
          {data.attributes.map((item, idx) => (
            <td key={`_td_${idx}`}>
              {(!item.length && actions({ id: body.id })) || dataType({ value: item, data: body })}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );

  const containerClass = classNames('table', { 'force-plus': forcePlus, closable });

  return (
    <Flex className={containerClass}>
      <Flex column>
        <Flex spaceBtw alignCenter>
          <h3>{data.type}</h3>
          <Flex justifyEnd>
            {data && (forcePlus || closable) && (
              <Button
                handleClick={() => handleDecision({ id: null, close: closable })}
                iconName={closable ? 'X' : 'Plus'}
                iconColor={!closable ? '#FFF' : '#111'}
                primary={!closable}
              />
            )}
          </Flex>
          <Flex hide={!!data.body.length || forcePlus} alignCenter>
            <OctagonAlert color="var(--color-note)" />
            <div>{t('noRecords')}</div>
          </Flex>
        </Flex>
        <Flex>
          <input type="text" className="search" placeholder={t('search')} value={search} onChange={handleSearch} />
        </Flex>

        <table className="fixed-header">
          <Header />
          <Body />
          <tfoot>
            <tr>
              <td colSpan={data.attributes.length}>{`${t('totalRecords')}: ${data.body.length}`}</td>
            </tr>
          </tfoot>
        </table>
      </Flex>
    </Flex>
  );
}
