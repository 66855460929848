import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateLicense from './Components/CreateClass';
import ClassesService from '../../services/Classes.service';
import { useNavigate } from 'react-router-dom';

export default function useClasses() {
  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState(null);
  const [abm, setAbm] = useState(null);
  const [dataUsers, setDataUsers] = useState(null);
  const { t } = useTranslation();
  const [buttons, setButtons] = useState([
    {
      action: { users: true },
      iconName: 'Users',
      caption: t('users'),
      iconSize: 20,
    },
    {
      action: { copy: true },
      iconName: 'Clipboard',
      caption: t('copy'),
      iconSize: 20,
    },
  ]);
  const [buttonsUsers, setButtonsUsers] = useState([
    {
      action: { add: true },
      iconName: 'UserRoundPlus',
      iconSize: 20,
    },
    {
      action: { remove: true },
      iconName: 'UserRoundMinus',
      iconSize: 20,
    },
  ]);

  const getData = async () => {
    const data = await ClassesService.get();
    setDataTable({
      type: 'Classes',
      header: [t('color'), t('code'), t('name'), t('actions')],
      attributes: ['color', 'code', 'name', ''],
      body: data,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteItemModel = (id) => {
    return;
  };

  const changeButton = ({ name, index }) => {
    setButtons(buttons.map((btn, i) => (i === index ? { ...btn, iconName: name } : btn)));
  };

  const handleDecision = ({ id, erase, copy, users, close }) => {
    const data = dataTable.body.find((it) => it.id === id);

    if (close) {
      setDataUsers(null);
      return;
    }

    if (copy) {
      navigator.clipboard.writeText(data.code);
      changeButton({ name: 'ClipboardCheck', index: 1 });
      setTimeout(() => {
        changeButton({ name: 'Clipboard', index: 1 });
      }, 1000);
      return;
    }

    if (users) {
      setDataUsers({
        type: `${t('class')} ${data.name}, ${t('users')}`,
        header: [t('name'), t('surname'), t('actions')],
        attributes: ['name', 'surname', ''],
        body: data.Users,
      });
      return;
    }

    if (erase) {
      deleteItemModel(id);
      return;
    }

    setDataUsers(null);
    setAbm(<CreateLicense goBack={goBack} data={data} />);
  };

  const goBack = () => {
    setAbm(null);
    getData();
  };

  const handleGoBack = (e) => {
    e?.preventDefault();
    if (abm) {
      goBack();
      return;
    }
    !abm && navigate('/');
  };

  return {
    dataTable,
    dataUsers,
    handleDecision,
    handleGoBack,
    t,
    abm,
    buttons,
    buttonsUsers,
  };
}
