import { Link } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Flex from '../../components/Flex/Flex';
import '../Login/Login.scss';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import useRegister from './useRegister';
import Icon from '../../components/Icon/Icon';

export default function Register() {
  const { handleGoogle, handleChange, handleSubmit, data, disable, t } = useRegister();

  return (
    <Flex className="login">
      <nav>
        <Link to="/auth/login">
          <Icon name={'ArrowLeft'} size={24} />
        </Link>
      </nav>
      <Flex column className="item sign jm">
        <Flex justifyCenter>
          <img width={80} src="/assets/logo.png" />
        </Flex>
        <small className="sp">"Sean por siempre alabados Jesús y María"</small>
        <form onSubmit={handleSubmit}>
          <Flex>
            <Flex className="data" gap={'10px'} column>
              <div>
                <h2>{t('login.signUp')}</h2>
                <small>{t('login.speech')} </small>
              </div>
              <div>
                <label>{t('name')}</label>
                <input name="name" type="text" required value={data.name} onChange={handleChange} />
              </div>
              <div>
                <label>{t('surname')}</label>
                <input name="surname" type="text" required value={data.surname} onChange={handleChange} />
              </div>
              <div>
                <label>{t('dni')}</label>
                <input
                  maxLength={8}
                  inputMode="numeric"
                  pattern="\d*"
                  name="dni"
                  type="text"
                  required
                  value={data.dni}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>{t('birth')}</label>
                <input name="birthdate" type="date" required value={data.birthdate} onChange={handleChange} />
              </div>
              <div>
                <label>{t('email')}</label>
                <input name="email" type="email" required value={data.username} onChange={handleChange} />
              </div>
              <div>
                <label>{t('login.password')}</label>
                <input
                  minLength="8"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={data.password}
                  onChange={handleChange}
                />
              </div>

              <Button primary disable={disable} type="submit" label={t('login.signUp')} />
              <label className="or">- {t('login.or')} -</label>
              <GoogleOAuthProvider clientId="852152572141-9sklrdbjqrgb8g87jh1kkbejavfmmsu1.apps.googleusercontent.com">
                <GoogleLogin
                  width={300}
                  onSuccess={handleGoogle}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />
              </GoogleOAuthProvider>
            </Flex>
            <Flex></Flex>
          </Flex>
        </form>
      </Flex>
      <Flex className="item image"></Flex>
    </Flex>
  );
}
