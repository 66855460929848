export const pt = {
  a: 'um',
  actions: 'Ações',
  action: 'Ação',
  activity: 'Atividade',
  activities: 'Atividades',
  addLicense: 'Adicionar licença',
  addOption: 'Adicionar opção',
  addTag: 'Adicionar etiqueta',
  birth: 'Data de nascimento',
  cancel: 'Cancelar',
  classes: 'Turmas',
  cloudSave: 'Salvo na nuvem',
  code: 'Código',
  color: 'Cor',
  content: 'Conteúdo',
  contentAdded: 'Conteúdo adicionado com sucesso',
  contentCreator: 'Criador de conteúdo',
  contentDeleted: 'Conteúdo excluído',
  contentSettings: 'Configurações de conteúdo',
  contentType: 'Tipo de conteúdo',
  contentUpdated: 'Conteúdo atualizado',
  dataSheet: 'Ficha técnica',
  deleteContent: 'Excluir conteúdo',
  description: 'Descrição',
  dni: 'CPF',
  dateFormat: 'DD/MM/YYYY',
  durations: [
    { value: 'days', text: 'Días' },
    { value: 'months', text: 'Meses' },
    { value: 'years', text: 'Anos' },
  ],
  email: 'E-mail',
  enableAutosave: 'Ativar salvamento automático',
  end: 'Você completou a atividade',
  errors: {
    email: 'E-mail já em uso',
  },
  exercises: {
    continue: 'Continuar',
    reset: 'Redefinir',
    end: 'Você completou a atividade',
    score: 'Sua pontuação é',
    of: 'de',
    type: 'Tipo',
    enterTag: 'Insira a tag',
    addTag: 'Adicionar tag',
  },
  expiresAt: 'Expira em',
  for: 'para ativar a licença',
  generate: 'Gerar licenças',
  here: 'aqui',
  image: 'Imagem',
  license: 'Licença',
  login: {
    account: 'Gerenciar conta',
    email: 'E-mail',
    here: 'aqui',
    myProfile: 'Meu perfil',
    or: 'ou',
    password: 'Senha',
    signIn: 'Entrar no sistema',
    signOut: 'Sair',
    signUp: 'Registrar-se',
    speech: 'Bem-vindo ao Brain, a revolução educacional impulsionada por inteligência artificial.',
    init: 'Iniciar sessão',
  },
  name: 'Nome',
  newClass: 'Nova turma',
  newLicense: 'Nova licença',
  newUser: 'Novo usuário',
  noLicense: 'Você não possui uma licença.',
  noRecords: 'Não há registros',
  noContents: 'Resgate uma licença para obter seu conteúdo!',
  option: 'Insira uma opção',
  or: 'ou em um botão no painel de cabeçalho.',
  pdfFile: 'Enviar arquivo PDF',
  please: 'Por favor, preencha os campos abaixo.',
  preview: 'Pré-visualização',
  profile: 'Perfil',
  publish: 'Publicar',
  published: 'Publicado',
  publications: 'Publicações',
  qty: 'Quantidade de usos',
  reset: 'Redefinir',
  save: 'Salvar',
  saveAll: 'Salvar tudo',
  savedLocal: 'Salvo localmente',
  score: 'Sua pontuação é',
  search: 'Buscar',
  select: 'Selecione a resposta correta',
  selectAtLeast: 'Selecione pelo menos uma publicação',
  students: 'Estudantes',
  surname: 'Sobrenome',
  tags: 'Etiquetas',
  templateQuiz: {
    addOption: 'Adicionar opção',
    addQuestion: 'Adicionar pergunta',
    name: 'Criador de Questionários',
    option: 'Insira uma opção',
    options: 'Opções',
    question: 'Insira sua pergunta',
    save: 'Salvar questionário',
    select: 'Selecione a resposta correta',
    title: 'Insira o título do questionário',
  },
  templates: 'Modelos',
  tips: {
    newContent: 'Clique no ícone do livro para criar conteúdo',
    newFile: 'Clique no ícone de arquivo para começar',
    newLicense: 'Clique no ícone de licença para resgatar uma',
  },
  title: 'Título',
  totalRecords: 'Número de registros',
  type: 'Tipo',
  writeHere: 'Vamos escrever uma história incrível',
  editor: {
    tool: {
      Text: 'Párrafo',
      Heading: 'Encabezado',
      List: 'Lista',
      Warning: 'Advertencia',
      Checklist: 'Lista de verificação',
      Quote: 'Cita',
      Code: 'Código',
      Delimiter: 'Delimitador',
      'Raw HTML': 'HTML sem procesar',
      Table: 'Tábua',
      Link: 'Link',
      Marker: 'Marcador',
      Bold: 'Negrita',
      Italic: 'Cursiva',
      InlineCode: 'Código em línea',
      Activity: 'Actividade',
      Image: 'Imagen',
    },

    ui: {
      blockTunes: {
        toggler: {
          'Click to tune': 'Faz clic para ajustar',
          'or drag to move': 'ou arrastra para mover',
        },
      },
      inlineToolbar: {
        converter: {
          'Convert to': 'Convertir a',
        },
      },
      toolbar: {
        toolbox: {
          Add: 'Adicionar',
        },
      },
    },
    tools: {
      warning: {
        Title: 'Título',
        Message: 'Mensaje',
      },
      link: {
        'Add a link': 'Adicionar um enlace',
      },
      stub: {
        'The block can not be displayed correctly.': 'O bloco não pode mostrarse corretamente',
      },
    },
    blockTunes: {
      delete: {
        Delete: 'Apagar',
      },
      moveUp: {
        'Move up': 'Mover hacia acima',
      },
      moveDown: {
        'Move down': 'Mover hacia abajo',
      },
    },
  },
  calendar: {
    title: 'Calendario escolar',
    set: {
      next: 'Seg',
      previous: 'Ant',
      today: 'Hoje',
      month: 'Mes',
      week: 'Semana',
      day: 'Dia',
    },
    event: 'Novo evento',
  },
};
