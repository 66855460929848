import Button from '../../../../components/Button/Button';
import Flex from '../../../../components/Flex/Flex';
import Icon from '../../../../components/Icon/Icon';
import './License.scss';
import useLicense from './useLicense';
export default function License({ handleCloseLicense, handleSubmit }) {
  const { license, handleChange, t } = useLicense();
  return (
    <Flex className="license">
      <form onSubmit={(e) => handleSubmit(e, license)}>
        <Flex className="data" gap={'20px'} column>
          <Flex handleClick={handleCloseLicense} className="close">
            <h2>{t('newLicense')}</h2>
            <Icon name="X" />
          </Flex>
          <div>
            <small>{t('please')}</small>
          </div>
          <input type="text" required value={license} onChange={handleChange} />
          <Button primary={true} type="submit" label={t('addLicense')} />
        </Flex>
      </form>
    </Flex>
  );
}
