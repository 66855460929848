import { useState } from 'react';
import { Wheel } from 'react-custom-roulette';
import Button from '../../../components/Button/Button';

const data = [
  {
    id: '8f85bb7b-a10f-40a5-9e06-eb69601ab35c',
    option: 'Final',
  },
  {
    id: '97d1bd88-4f3f-4a93-b779-e32133dadf47',
    option: 'Pregunta',
  },
  {
    id: 'ee136d62-cc3a-43ce-90d4-e36bcc59bbf6',
    option: 'Salir',
  },
  {
    id: 'fed31520-28a6-40fc-b3b4-d88b0d9480e2',
    option: 'Pasa',
  },
];

export default function RouletteGame() {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const handleSpinClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * data.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };
  return (
    <>
      <Button primary label={'spin'} handleClick={handleSpinClick} />
      <Wheel
        mustStartSpinning={mustSpin}
        spinDuration={[0.2]}
        prizeNumber={prizeNumber}
        data={data}
        textColors={['#111']}
        fontSize={25}
        outerBorderColor={['#ccc']}
        outerBorderWidth={[4]}
        innerBorderColor={['#FFF']}
        radiusLineColor={['#FFF']}
        radiusLineWidth={[5]}
        backgroundColors={['green', 'orange', 'yellow', 'brown']}
        onStopSpinning={() => {
          setMustSpin(false);
        }}
      />
    </>
  );
}
