import { useEffect, useState } from 'react';
import TemplatesService from '../../services/Templates.service';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function useTemplates() {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const data = await TemplatesService.get();
        setTemplates(data);
      } catch (error) {
        setError(error);
        console.error('Failed to fetch templates:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  const handleSelect = (id) => {
    navigate(`/activities/template/${id}`);
  };

  return { templates, t, handleSelect, loading, error };
}
