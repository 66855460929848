import Avatar, { genConfig } from 'react-nice-avatar';
import Flex from '../Flex/Flex';
import './MyAvatar.scss';
import useMyAvatar from './useMyAvatar';
import Pieces from './Pieces';

export default function MyAvatar({ userConfig, edit, handleChange }) {
  const { config, handleClick, color, uConfig } = useMyAvatar({ userConfig: userConfig, handleChange: handleChange });

  return (
    <Flex column alignCenter className="avatar">
      <Avatar style={{ width: '8rem', height: '8rem' }} {...genConfig(uConfig)} />
      {edit && (
        <Flex gap={'12px'} className="lavatar">
          {config.map((element) => {
            return <Pieces color={color} handleClick={handleClick} key={element.type} element={element} />;
          })}
        </Flex>
      )}
    </Flex>
  );
}
