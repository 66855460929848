import Button from '../../../components/Button/Button';
import CheckBox from '../../../components/Checkbox/CheckBox';
import Flex from '../../../components/Flex/Flex';
import NumberInput from '../../../components/NumberInput/NumberInput';
import useCreateLicense from './useCreateLicense';

export default function CreateLicense({ data, goBack }) {
  const {
    handleQty,
    qty,
    handleSubmit,
    published,
    handleCheckBox,
    t,
    duration,
    handleSelect,
    number,
    handleNumber,
    startAt,
    handleStartAt,
  } = useCreateLicense({
    data: data,
    goBack: goBack,
  });

  return (
    <Flex className="form">
      <form onSubmit={handleSubmit}>
        <Flex column className="item simple" gap={'20px'}>
          <h3>{t('newLicense')}</h3>
          <small>{t('dataSheet')}</small>
          <Flex column>
            <label>{t('qty')}</label>
            <NumberInput required value={qty} onChange={handleQty} />
          </Flex>
          <div>
            <label>{t('expiresAt')}</label>
            <Flex>
              <NumberInput required value={number} onChange={handleNumber} />
              <select onChange={handleSelect} required>
                {duration.map((item, index) => {
                  return (
                    <option key={`_sel_${index}`} value={item.value}>
                      {item.text}
                    </option>
                  );
                })}
              </select>
            </Flex>
          </div>
          <div>
            <label>{t('startAt')}</label>
            <input value={startAt} type="date" onChange={handleStartAt} />
          </div>
          <Flex>
            <label>{t('publications')}</label>
          </Flex>
          <div className="scroll">
            {published
              .filter((item) => !item.hide)
              .map((item, index) => {
                return (
                  <Flex spaceBtw alignCenter key={`i_${index}`}>
                    <div>{item.Content.title}</div>
                    <div className="flex">
                      <CheckBox
                        handleCheckBox={(e) => handleCheckBox({ item: item, value: e.target.checked })}
                        checked={item.checked}
                      />
                    </div>
                  </Flex>
                );
              })}
          </div>
          <Flex justifyEnd>
            <Button handleClick={goBack} type="button" label={t('cancel')} />
            <Button primary={true} type="submit" label={t('save')} />
          </Flex>
        </Flex>
      </form>
    </Flex>
  );
}
