import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClassesService from '../../../services/Classes.service';
import UsersService from '../../../services/Users.service';
import moment from 'moment-timezone';
import useToast from '../../../hooks/useToast';

export default function useCreateUser({ data, goBack }) {
  const { t } = useTranslation();
  const { updateToast } = useToast();
  const [user, setUser] = useState({
    name: data?.name || '',
    surname: data?.surname || '',
    email: data?.email || '',
    dni: data?.dni || '',
    birthdate: data?.birthdate ? moment.tz(data.birthdate, 'UTC').format('YYYY-MM-DD') : '',
    userType: 'student',
    classes: [],
  });

  const [classes, setClasses] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    const fetch = async () => {
      const data = await ClassesService.get();
      data.forEach((element) => {
        let el = data && data?.find((it) => it.id === element.id);
        element.checked = el ? true : false;
      });
      setClasses(data);
    };
    fetch();
  }, []);

  const handleUser = (e) => {
    let value = e.target.value;
    if (e.target.name === 'dni') {
      value = value.replace(/\D/g, '').slice(0, 8);
    }
    setUser({ ...user, [e.target.name]: value });
  };

  const reset = () => {
    setUser({ name: '', surname: '', email: '', userType: 'student', classes: [], birthdate: '', dni: '' });
    setClasses(
      [...classes].map((item) => ({
        ...item,
        checked: false,
      }))
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const filteredItems = classes.filter((item) => item.checked).map((item) => item.id);

    if (!filteredItems.length) {
      updateToast({ text: t('selectAtLeastOneClass') });
      return;
    }

    if (user.dni?.length < 7) {
      updateToast({ text: t('dniInfo') });
      return;
    }

    user.classes = filteredItems;

    if (data) {
      await UsersService.put({ id: data.id, data: user });
      updateToast({ text: 'Dados atualizados' });
      goBack();
      return;
    }

    try {
      await UsersService.post({ data: user });
      updateToast({ text: 'Criado com sucesso' });
      reset();
      ref.current.focus();
    } catch (error) {
      updateToast({ text: t(error.response?.data?.message) });
    }
  };

  const handleCheckBox = ({ item, value }) => {
    setClasses(classes.map((it) => (it.id === item.id ? { ...it, checked: value } : it)));
  };

  return {
    handleSubmit,
    handleUser,
    handleCheckBox,
    user,
    t,
    ref,
    classes,
  };
}
