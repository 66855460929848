export default function CoreButton({ color, bgColor, handleClick, children }) {
  const style = {
    outline: 'none',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    padding: '10px',
    backgroundColor: bgColor || '#00005c',
    color: color || 'inherit',
  };
  return (
    <button style={style} onClick={handleClick}>
      {children}
    </button>
  );
}
