import { useEffect, useRef, useState } from 'react';

export default function useTags({ dataTags, handleChange }) {
  const [tags, setTags] = useState(dataTags || []);
  const [tag, setTag] = useState('');
  const ref = useRef();
  const [typingTimeout, setTypingTimeout] = useState(null);
  const delay = 500;

  useEffect(() => {
    ref?.current?.focus();
  }, []);

  const handleChangeTag = (e) => {
    const value = e.target.value;
    setTag(value);

    // Limpiar el timeout anterior
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Iniciar un nuevo timeout
    setTypingTimeout(
      setTimeout(() => {
        if (value.trim().length > 0) {
          createTag(value);
        }
      }, delay)
    );
  };

  const createTag = (value) => {
    const _tags = [...tags, value];
    setTags(_tags);
    handleChange(_tags);
    setTag('');
  };

  const handleDeleteTag = (index) => {
    const _tags = [...tags];
    _tags.splice(index, 1);
    setTags(_tags);
    handleChange(_tags);
  };

  return { ref, tags, tag, handleChangeTag, handleDeleteTag };
}
