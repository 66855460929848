import axios from './Client';

const post = async ({ data }) => {
  const response = await axios.post(`/publications`, data);
  return response.data;
};
const getForLicenses = async () => {
  const response = await axios.get(`/publications`);
  return response.data;
};

const getAllForUser = async () => {
  const response = await axios.get(`/publications/users`);
  return response.data;
};

const redeemed = async () => {
  const response = await axios.get(`/publications/redeemed`);
  return response.data;
};

const getActivitiesFromPublications = async ({ id }) => {
  const response = await axios.get(`/publications/${id}/activities`);
  return response.data;
};

const put = async ({ data, id }) => {
  const response = await axios.put(`/publications/${id}`, data);
  return response.data;
};

export default { post, put, getForLicenses, redeemed, getAllForUser, getActivitiesFromPublications };
