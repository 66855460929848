import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

export default function Nav({ goTo = '/', handleClick, title, children }) {
  const Decision = ({ children }) => {
    return (handleClick && <Link onClick={handleClick}>{children}</Link>) || <Link to={goTo}>{children}</Link>;
  };
  return (
    <nav className="header">
      {
        <Decision>
          <ArrowLeft />
          {title}
        </Decision>
      }
      {children}
    </nav>
  );
}
