import Flex from '../../components/Flex/Flex';
import Loader from '../../components/Loader/Loader';
import Table from '../../components/Table/Table';
import useUsers from './useUsers';
import Nav from '../../components/Nav/Nav';

export default function Users() {
  const { dataTable, handleDecision, handleGoBack, t, abm, buttons } = useUsers();

  if (!dataTable) return <Loader />;

  return (
    <Flex column>
      <Nav handleClick={handleGoBack} title={t('users')} />
      <Flex className="licenses">
        {abm && abm}
        {!abm && dataTable && (
          <Table t={t} data={dataTable} handleDecision={handleDecision} forcePlus buttons={buttons} />
        )}
      </Flex>
    </Flex>
  );
}
