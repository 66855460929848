import axios from './Client';

const get = async () => {
  const response = await axios.get(`/classes`);
  return response.data;
};

const post = async ({ data }) => {
  const response = await axios.post(`/classes`, data);
  return response.data;
};

export default { get, post };
