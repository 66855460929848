import { TriangleAlert } from 'lucide-react';
import Button from '../../../components/Button/Button';
import CheckBox from '../../../components/Checkbox/CheckBox';
import Flex from '../../../components/Flex/Flex';
import useCreateUser from './useCreateUser';

export default function CreateUser({ data, goBack }) {
  const { handleSubmit, handleUser, handleCheckBox, user, t, ref, classes } = useCreateUser({
    data: data,
    goBack: goBack,
  });

  return (
    <Flex className="form">
      <form onSubmit={handleSubmit}>
        <Flex column className="item simple">
          <h3>{t('newUser')}</h3>
          <small>{t('dataSheet')}</small>
          <div>
            <label>{t('name')}</label>
            <input ref={ref} type="text" required name="name" value={user.name} onChange={handleUser} />
          </div>
          <div>
            <label>{t('surname')}</label>
            <input type="text" required name="surname" value={user.surname} onChange={handleUser} />
          </div>
          <div>
            <label>{t('email')}</label>
            <input disabled={data} type="email" required name="email" value={user.email} onChange={handleUser} />
          </div>
          <div>
            <label>{t('dni')}</label>
            <input
              placeholder={t('dniInfo')}
              disabled={data}
              type="text"
              required
              name="dni"
              value={user.dni}
              onChange={handleUser}
            />
          </div>
          <div>
            <label>{t('birth')}</label>
            <input disabled={data} type="date" required name="birthdate" value={user.birthdate} onChange={handleUser} />
          </div>
          <div>
            <label>{t('classes')}</label>
            <div className="scroll">
              {!classes?.length && (
                <Flex alignCenter>
                  <TriangleAlert />
                  <small className="warn">Debe tener al menos una clase</small>
                </Flex>
              )}
              {classes?.map((item, index) => {
                return (
                  <Flex spaceBtw alignCenter key={`i_${index}`}>
                    <div>{item.name}</div>
                    <div className="flex">
                      <CheckBox
                        handleCheckBox={(e) => handleCheckBox({ item: item, value: e.target.checked })}
                        checked={item.checked}
                      />
                    </div>
                  </Flex>
                );
              })}
            </div>
          </div>
          <Flex className="buttons" justifyEnd gap={'10px'}>
            <Button gray handleClick={goBack} type="button" label={t('cancel')} />
            <Button primary={true} type="submit" label={t('save')} />
          </Flex>
        </Flex>
      </form>
    </Flex>
  );
}
