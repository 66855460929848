import Flex from '../../../../components/Flex/Flex';
import Button from '../../../../components/Button/Button';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './Set.scss';
import useSet from './useSet';
import Icon from '../../../../components/Icon/Icon';
import Loader from '../../../../components/Loader/Loader';

export default function Set({ handleClose, data, id }) {
  const {
    handleTitleChange,
    title,
    handleDescriptionChange,
    description,
    handleSubmit,
    disable,
    handleSelect,
    options,
    option,
    handleImage,
    handlePdf,
    handleDeleteContent,
    t,
  } = useSet({ data: data, handleClose: handleClose, id: id });

  if (!options) return <Loader />;

  return (
    <Flex className="set">
      <form onSubmit={handleSubmit}>
        <Flex className="data" gap={'10px'} column>
          <Flex handleClick={handleClose} className="close">
            <h2>{t('contentSettings')}</h2>
            <Icon name="X" />
          </Flex>
          <div>
            <small>{t('please')}</small>
          </div>
          <div>
            <label>{t('title')}</label>
            <input type="text" required value={title} onChange={handleTitleChange} />
          </div>
          <div>
            <label>{t('description')}</label>
            <textarea required value={description} onChange={handleDescriptionChange} />
          </div>
          <div>
            <label>{t('image')}</label>
            <input
              required
              disabled={data}
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={handleImage}
            />
          </div>
          <div>
            <label>{t('contentType')}</label>
            <Dropdown
              disabled={data}
              required
              value={options[0]}
              options={options}
              onChange={handleSelect}
              placeholder="Select an option"
            />
          </div>
          {option === '18c27260-6248-49c4-8e99-570d89dfae45' && (
            <div>
              <label>{t('pdfFile')}</label>
              <input disabled={data} type="file" required accept="application/pdf" onChange={handlePdf} />
            </div>
          )}
          <div className="sep" />
          <Button primary={true} disable={disable} type="submit" label={t('save')} />
          {id && <Button handleClick={handleDeleteContent} secondary label={t('deleteContent')} />}
        </Flex>
      </form>
    </Flex>
  );
}
