import Flex from '../../components/Flex/Flex';
import Loader from '../../components/Loader/Loader';
import Table from '../../components/Table/Table';
import useLicenses from './useLicenses';
import './Licenses.scss';
import Nav from '../../components/Nav/Nav';

export default function Licenses() {
  const { dataTable, handleDecision, t, abm, buttons, handleGoBack } = useLicenses();

  if (!dataTable) return <Loader />;

  return (
    <Flex column>
      <Nav handleClick={handleGoBack} title={t('generate')} />
      <Flex className="licenses">
        {abm && abm}
        {!abm && dataTable && (
          <Table t={t} data={dataTable} handleDecision={handleDecision} buttons={buttons} forcePlus />
        )}
      </Flex>
    </Flex>
  );
}
