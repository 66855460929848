import { useState } from 'react';
import { useAuth } from '../../hooks/AuthProvider';
import { useTranslation } from 'react-i18next';
import useToast from '../../hooks/useToast';

export default function useRegister() {
  const [data, setData] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    dni: '',
    birthdate: '',
    userType: 'teacher',
  });
  const { toast } = useToast();
  const [disable, setDisable] = useState(toast.text.length > 0);
  const verifyEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  const { t } = useTranslation();
  const auth = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!verifyEmail(data.email)) {
      updateToast({ text: t('invalidEmail') });
      return false;
    }
    if (data.password.trim().length < 8) {
      updateToast({ text: t('login.passwordMin') });
      return false;
    }
    setDisable(true);
    auth.registerUser(data);
  };
  const handleChange = (e) => {
    e.preventDefault();
    let item = { ...data };
    item[e.target.name] = e.target.name === 'dni' ? e.target.value.replace(/[^0-9]/g, '') : e.target.value;
    setData(item);
  };

  const handleGoogle = (credentialResponse) => {
    auth.loginGoogleAction({ token: credentialResponse.credential });
  };
  return { handleSubmit, handleChange, handleGoogle, data, disable, t };
}
