import axios from './Client';

const get = async () => {
  const response = await axios.get(`/calendars`);
  return response.data;
};

const getEventsCalendarByClassId = async ({ id }) => {
  const response = await axios.get(`/calendars/classes/${id}`);
  return response.data;
};

const postCalendar = async ({ data }) => {
  const response = await axios.post(`/calendars`, data);
  return response.data;
};

const getById = async ({ id }) => {
  const response = await axios.get(`/calendars/${id}`);
  return response.data;
};
const postEvent = async ({ data }) => {
  const response = await axios.post(`/calendars/events`, data);
  return response.data;
};
const putEvent = async ({ data, id }) => {
  const response = await axios.put(`/calendars/events/${id}`, data);
  return response.data;
};

const delEvent = async ({ id }) => {
  const response = await axios.delete(`/calendars/events/${id}`);
  return response.data;
};

export default { get, getById, getEventsCalendarByClassId, postCalendar, postEvent, putEvent, delEvent };
