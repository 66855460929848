import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActivitiesService from '../../services/Activities.service';
import { useNavigate } from 'react-router-dom';

export default function useActivities({ onClose, onSelect, fromStudio }) {
  const [dataTable, setDataTable] = useState(null);
  const [quiz, setQuiz] = useState(null);
  const [preview, setPreview] = useState(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [buttons, setButtons] = useState([
    {
      action: { select: onClose, preview: !onClose },
      iconName: onClose ? 'MousePointerClick' : 'Eye',
      iconSize: 20,
    },
    {
      action: { edit: true },
      iconName: 'Pencil',
      iconSize: 20,
    },
    {
      action: { erase: true },
      iconName: 'Trash',
      iconSize: 20,
    },
  ]);

  const getActivitites = async () => {
    const data = await ActivitiesService.all();
    setDataTable({
      type: t('Activities'),
      header: [t('id'), t('title'), t('tags'), t('templates'), t('actions')],
      attributes: ['id', 'title', 'tags', 'Template.name', ''],
      body: data,
    });
  };

  useEffect(() => {
    getActivitites();
  }, []);

  const handleDecision = ({ id, erase, select, preview }) => {
    if (!id) {
      if (fromStudio) {
        setQuiz([]);
        return;
      }
      //navigate('/activities/new');
      //setQuiz([]);

      navigate('/activities/templates');
      return;
    }

    const json = dataTable.body.find((it) => it.id === id);

    if (erase) {
      console.log(id);
      return;
    }

    if (preview) {
      setPreview(json);
      return;
    }

    if (select) {
      onSelect(json);
      handleClose();
      return;
    }
    setQuiz(json);
    // setAbm(<CreateLicense goBack={goBack} data={data} />);
  };

  const handleExit = (e) => {
    e.preventDefault();
    if (quiz) setQuiz(null);
    else if (!quiz && !onSelect) navigate('/');
  };

  const handleClose = (json) => {
    if (json?.id) {
      if (fromStudio) {
        onSelect(json);
        handleClose();
      } else {
        setQuiz(null);
        getActivitites();
      }
      return;
    }

    setPreview(null);

    onClose && onClose();
  };

  return { dataTable, handleDecision, t, quiz, handleClose, handleExit, buttons, preview };
}
