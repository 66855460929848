import { useRecoilState } from 'recoil';
import { toastState } from '../utils/states';

const useToast = () => {
  const [toast, setToast] = useRecoilState(toastState);

  const updateToast = (newToast) => {
    setToast((prevToast) => ({
      ...prevToast,
      ...newToast,
    }));
  };

  return { toast, updateToast };
};

export default useToast;
