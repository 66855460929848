import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PublicationsService from '../../services/Publications.service';
import useToast from '../../hooks/useToast';
import { useAuth } from '../../hooks/AuthProvider';

const VIEWER = process.env.REACT_APP_VIEWER_URL_DEV;

export default function usePdf() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { toast, updateToast } = useToast();
  const { token } = useAuth();
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1.6);
  const [idPublication, setIdPublication] = useState(null);
  const [drawings, setDrawings] = useState([]); // Guardar los dibujos
  const [options, setOptions] = useState({
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
    httpHeaders: {
      Authorization: token,
    },
  });

  const [showSet, setShowSet] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const data = await PublicationsService.getForLicenses({ id: id });
      if (data.length > 0) {
        setData(data[0].Content);
      }
    };
    fetch();
  }, []);

  const handleLoadProgress = ({ loaded, total }) => {
    //console.log(loaded, total);
  };

  const handleLoadSuccess = (e) => {
    setNumPages(e.numPages);
  };

  const handlePublish = async () => {
    if (idPublication) {
      updateToast({ text: t('published'), background: toast.type.SUCCESS });
      return;
    }

    const payload = { data: { json: {}, id: id, activities: [] } };
    await PublicationsService.post(payload);
    setIdPublication(300);
    updateToast({ text: t('published'), background: toast.type.SUCCESS });
  };

  const handleShowSet = () => {
    setShowSet(!showSet);
  };

  const handleView = () => {
    window.open(`${VIEWER}/?viewer=pdf&post=${id}&token=${token}`, '_blank');
  };
  const handleScroll = (event) => {
    const container = event.target;
    const pages = Array.from(container.querySelectorAll('.react-pdf__Page'));

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const rect = page.getBoundingClientRect();

      // Ajustar las coordenadas teniendo en cuenta el zoom
      const adjustedTop = rect.top / scale;
      const adjustedBottom = rect.bottom / scale;

      if (adjustedBottom >= 0 && adjustedTop <= window.innerHeight / scale && currentPage !== i + 1) {
        console.log(currentPage);
        setCurrentPage(i + 1);
        break;
      }
    }
  };

  const zoomIn = () => {
    setScale(scale + 0.2);
  };

  const zoomOut = () => {
    setScale(scale - 0.2);
  };

  return {
    pageNumber,
    numPages,
    options,
    handlePublish,
    handleLoadProgress,
    handleLoadSuccess,
    handleShowSet,
    handleView,
    handleScroll,
    id,
    t,
    showSet,
    data,
    zoomIn,
    zoomOut,
    scale,
    drawings,
    setDrawings,
  };
}
