import { useState } from 'react';
import { useAuth } from '../../hooks/AuthProvider';
import { useTranslation } from 'react-i18next';
import useToast from '../../hooks/useToast';

export default function useLogin() {
  const auth = useAuth();
  const { t } = useTranslation();
  const { toast, updateToast } = useToast();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [disable, setDisable] = useState(toast.text.length > 0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);
    try {
      await auth.loginAction({ email: username, password: password });
    } catch (error) {
      updateToast({ text: error.response.data.message });
      setDisable(false);
    }
  };

  const handleChangeUser = (e) => {
    setUsername(e.target.value);
  };
  const handleChangePass = (e) => {
    setPassword(e.target.value);
  };
  const handleGoogle = async (credentialResponse) => {
    try {
      await auth.loginGoogleAction({ token: credentialResponse.credential });
    } catch (error) {
      updateToast({ text: error.response.data.message });
      setDisable(false);
    }
  };

  return {
    handleSubmit,
    username,
    password,
    handleChangeUser,
    handleChangePass,
    handleGoogle,
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    disable,
    t,
    brand: process.env.REACT_APP_TENANT,
  };
}
