import Block from '../../../components/Block/Block';
import Quiz from '../../Content/components/Activities/Quiz/Quiz';

export default function Preview({ data, handleClose }) {
  return (
    <>
      <Block handleClick={handleClose} />
      <div className="preview">
        <Quiz data={data} />
      </div>
    </>
  );
}
