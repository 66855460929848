import Flex from '../../components/Flex/Flex';
import './Dash.scss';
import Brand from '../../components/Brand/Brand';
import useDash from './useDash';
import Loader from '../../components/Loader/Loader';
import Article from './components/article/Article';
import Profile from './components/profile/Profile';
import License from './components/license/License';
import Block from '../../components/Block/Block';
import { CircleAlert } from 'lucide-react';
import Menu from './components/menu';

export default function Dash() {
  const {
    contents,
    showProfile,
    handleCloseProfile,
    handleCloseLicense,
    handleDecision,
    showLicense,
    handleSubmit,
    showBlock,
    path,
    isEditor,
    userMenu,
    t,
  } = useDash();

  return (
    <Flex className="dash" column>
      {showBlock && <Block />}
      <Profile handleCLose={handleCloseProfile} show={showProfile} />
      {showLicense && <License handleSubmit={handleSubmit} handleCloseLicense={handleCloseLicense} />}
      <nav>
        <Brand />
        <Flex>
          <Menu
            isEditor={isEditor}
            userMenu={userMenu}
            haveContent={contents.length}
            t={t}
            handleDecision={handleDecision}
          />
        </Flex>
      </nav>
      <section className="articles">
        {contents?.map((item, index) => {
          return (
            <Article
              item={item}
              image={`${path}/${item.DefaultStorage.path}`}
              key={`article_${index}`}
              isEditor={isEditor}
            />
          );
        })}
      </section>
      {!contents.length && !isEditor && (
        <Flex column>
          <Flex justifyCenter alignCenter column>
            <CircleAlert color={'var(--color-note)'} size={48} />
            <h3>{t('noContents')}</h3>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
