import { useEffect, useState } from 'react';
import LicenseService from '../../services/License.service';
import { useTranslation } from 'react-i18next';
import CreateLicense from './Components/CreateLicense';

import { useNavigate } from 'react-router-dom';
import { TriangleAlert } from 'lucide-react';

export default function useLicenses() {
  const [dataTable, setDataTable] = useState(null);
  const [abm, setAbm] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [buttons, setButtons] = useState([
    {
      action: { edit: true },
      caption: t('edit'),
      iconName: 'Pencil',
      iconSize: 20,
    },
    {
      action: { copy: true },
      iconName: 'Clipboard',
      caption: t('copy'),
      iconSize: 20,
    },
  ]);

  const getData = async () => {
    const data = await LicenseService.get();
    setDataTable({
      type: 'Licenses',
      header: [t('code'), t('qty'), t('startAt'), t('expiresAt'), t('actions')],
      attributes: ['code', 'qty', 'startAt', 'expiresAt', ''],
      control: 'expiresAt',
      body: data,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteItemModel = (id) => {
    return;
  };

  const changeButton = ({ name, index }) => {
    setButtons(buttons.map((btn, i) => (i === index ? { ...btn, iconName: name } : btn)));
  };

  const handleDecision = ({ id, erase, copy }) => {
    if (erase) {
      deleteItemModel(id);
      return;
    }

    const data = dataTable.body.find((it) => it.id === id);

    if (copy) {
      navigator.clipboard.writeText(data.code);
      /*
      changeButton({ name: 'ClipboardCheck', index: 1 });
      setTimeout(() => {
        changeButton({ name: 'Clipboard', index: 1 });
      }, 1000);
      */
      return;
    }

    setAbm(<CreateLicense goBack={goBack} data={data} />);
  };

  const goBack = () => {
    setAbm(null);
    getData();
  };

  const handleGoBack = (e) => {
    e?.preventDefault();
    if (abm) {
      goBack();
      return;
    }
    !abm && navigate('/');
  };

  return {
    dataTable,
    handleDecision,
    handleGoBack,
    t,
    abm,
    buttons,
  };
}
