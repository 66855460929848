import { Link } from 'react-router-dom';
import MyAvatar from '../../../../components/Avatar/MyAvatar';
import Button from '../../../../components/Button/Button';
import Flex from '../../../../components/Flex/Flex';
import Icon from '../../../../components/Icon/Icon';
import './Profile.scss';
import useProfile from './useProfile';
import Loader from '../../../../components/Loader/Loader';

export default function Profile({ show, handleCLose }) {
  const { user, handleLogout, ref, t } = useProfile({ handleCLose: handleCLose });

  if (!user) return <Loader />;

  return (
    <div className={`profile --${show}`} ref={ref}>
      <Flex column>
        <Flex handleClick={handleCLose} className="close">
          <Icon name={'X'} />
        </Flex>
        <Flex alignCenter column gap={'15px'}>
          <p>{user.email}</p>
          <MyAvatar userConfig={user.avatar} />
          <p>{`${t('hi')} ${user.name} ${user.surname}`}!</p>
          <Flex>
            <Link to="/account">
              <Button secondary iconName={'SquareUserRound'} iconColor="#FFF" label={t('login.account')} />
            </Link>
            <Button
              primary
              handleClick={handleLogout}
              iconName={'LogOut'}
              iconColor="#FFF"
              label={t('login.signOut')}
            />
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
}
