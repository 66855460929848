import { useEffect, useState } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List, { toolbox } from '@editorjs/list';
import ImageTool from '@editorjs/image';
import Table from '@editorjs/table';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';

import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { publish, subscribe, unsubscribe } from '../../utils/events';
import ContentsService from '../../services/Contents.service';
import { useAuth } from '../../hooks/AuthProvider';
import { hasDifferences } from '../../utils/Utils';
import PublicationsService from '../../services/Publications.service';
import Activity from '../../utils/tools/Activity';
import Embed from '../../utils/tools/Embed';
import { nanoid } from 'nanoid';
import useToast from '../../hooks/useToast';
const environment = process.env.REACT_APP_ENVIROMENT;
const VIEWER = process.env[`REACT_APP_API_VIEWER_URL_${environment}`];

export default function useStudio() {
  const { guid } = useParams();
  const [editor, set] = useState(null);
  const [autoSave, setAutoSave] = useState(false);
  const [compareLast, setCompareLast] = useState({});
  const [showSet, setShowSet] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activity, setActivity] = useState(null);
  const [data, setData] = useState(null);
  const [noData, setNoData] = useState(false);
  const [idPublication, setIdPublication] = useState(null);
  const { toast, updateToast } = useToast();

  const { t } = useTranslation();
  const { token } = useAuth();
  const navigate = useNavigate();

  const createEditor = async (node) => {
    const _editor = new EditorJS({
      holder: 'editorjs',
      placeholder: t('writeHere'),
      minHeight: 1,
      onReady: () => {
        _editor.render(node?.data ? JSON.parse(node.data) : { blocks: [] });
        setCompareLast(node?.data ? JSON.parse(node.data) : { blocks: [] });
        set(_editor);
      },
      tools: {
        header: {
          class: Header,
          inlineToolbar: ['link'],
        },
        list: {
          class: List,
          inlineToolbar: true,
        },
        embed: Embed,
        table: Table,
        quote: Quote,
        activity: {
          class: Activity,
          config: {
            handleClick: (act) => {
              setActivity(act);
              setShowModal(true);
            },
          },
        },
        image: {
          class: ImageTool,
          config: {
            additionalRequestHeaders: {
              Authorization: token,
            },
            endpoints: {
              byFile: `${process.env[`REACT_APP_API_URL_${process.env.REACT_APP_ENVIROMENT}`]}/uploadFile`,
            },
          },
        },
      },
      i18n: {
        messages: {
          ui: t('editor.ui'),
          toolNames: t('editor.tool'),
          tools: t('editor.tools'),
          blockTunes: t('editor.blockTunes'),
        },
      },
    });
  };

  const handlerSaveTree = async (e) => {
    const newData = e.detail.data.map((item) => ({ ...item, id: item.id.includes('simple') ? nanoid() : item.id }));
    const result = newData
      .map((item) => {
        const parsedData = JSON.parse(item.data);
        const activityIds = parsedData.blocks
          .filter((block) => block.type === 'activity')
          .map((block) => block.data.id);

        if (activityIds.length > 0) {
          return {
            BlockId: item.id,
            ids: activityIds,
          };
        }
      })
      .filter(Boolean);

    const payload = { data: { json: newData, id: guid, activities: result }, id: guid };

    if (e.detail.publish) {
      if (e.detail.idPublication) {
        await PublicationsService.put({
          id: e.detail.idPublication,
          data: { json: newData, id: guid, activities: result },
        });

        updateToast({ text: t('published'), background: toast.type.SUCCESS });

        return;
      }

      const data = await PublicationsService.post(payload);

      e.detail.setIdPublication(data.id);
      updateToast({ text: t('published'), background: toast.type.SUCCESS });

      return;
    }

    if (e.detail.cloud) {
      await ContentsService.history(payload);
      updateToast({ text: t('cloudSave'), background: toast.type.SUCCESS });
    } else updateToast({ text: t('savedLocal'), background: toast.type.SUCCESS });
  };

  useEffect(() => {
    subscribe('SAVE_TREE_SENDED', handlerSaveTree);
    return () => {
      unsubscribe('SAVE_TREE_SENDED', handlerSaveTree);
    };
  }, []);

  useEffect(() => {
    if (guid) {
      const fetch = async () => {
        try {
          const data = await ContentsService.byGuid({ guid: guid });
          setData(data || null);
          if (data.Content?.Publications?.length > 0) {
            setIdPublication(data.Content?.Publications[0].id);
          }
        } catch (err) {
          console.error(err);
        }
      };
      fetch();
    } else {
      setNoData(true);
    }
  }, [guid]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoSave) {
        handleSave();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [autoSave]);

  const handleSave = (cloud) => {
    editor
      .save()
      .then((outputData) => {
        if (cloud) setCompareLast(outputData);

        //  if (hasDifferences(outputData.blocks, compareLast.blocks))
        publish('SAVE_TREE', {
          data: outputData,
          cloud: cloud,
        });
      })
      .catch((error) => {
        console.log('Saving failed: ', error);
      });
  };

  const handleShowSet = () => {
    setShowSet(!showSet);
  };

  const handleTree = () => {
    setShowTree(!showTree);
  };

  const handleCloseSet = () => {
    if (!guid) navigate('/');
    else handleShowSet();
  };

  const handleSelect = (node) => {
    if (editor) {
      set(null);
      editor.destroy();
    }

    createEditor(node);
  };

  const handleAutoSave = () => {
    setAutoSave(!autoSave);
  };

  const handleView = () => {
    window.open(`${VIEWER}/?viewer=html&post=${guid}&token=${token}&preview=true`, '_blank');
  };

  const handlePublish = () => {
    publish('SAVE_TREE', { publish: true, setIdPublication: setIdPublication, idPublication: idPublication });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSelectActivity = (json) => {
    activity.querySelector('input').value = json.id;
    activity.querySelector('input').json = json.data;
  };

  return {
    readOnly: !editor,
    handleSave,
    handleShowSet,
    handleCloseSet,
    showSet,
    handleTree,
    autoSave,
    handleAutoSave,
    handleSelect,
    guid,
    t,
    handleView,
    handlePublish,
    showModal,
    handleClose,
    handleSelectActivity,
    guid,
    data,
    noData,
  };
}
