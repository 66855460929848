import Flex from '../../components/Flex/Flex';
import Loader from '../../components/Loader/Loader';
import Table from '../../components/Table/Table';
import useClasses from './useClasses';
import Nav from '../../components/Nav/Nav';

export default function Classes() {
  const { dataTable, dataUsers, handleDecision, handleGoBack, t, abm, buttons, buttonsUsers } = useClasses();

  if (!dataTable) return <Loader />;

  return (
    <Flex column>
      <Nav handleClick={handleGoBack} title={t('classes')} />
      <Flex column className="licenses">
        {abm && abm}
        {!abm && dataTable && (
          <Table t={t} data={dataTable} handleDecision={handleDecision} forcePlus buttons={buttons} />
        )}
        {dataUsers && <Table t={t} data={dataUsers} closable handleDecision={handleDecision} buttons={buttonsUsers} />}
      </Flex>
    </Flex>
  );
}
