import { X } from 'lucide-react';
import Block from '../../../components/Block/Block';
import Flex from '../../../components/Flex/Flex';
import Button from '../../../components/Button/Button';

export default function Message({ t, isEditor, data, handleClose, handleDelete }) {
  return (
    <>
      <Block />
      <Flex column className="message form">
        <Flex handleClick={handleClose} spaceBtw className="close" alignCenter>
          <h3>{t('calendar.event')}</h3>
          <X />
        </Flex>
        <h4 className="title">{data.title}</h4>
        <h5 className="description">{data.description}</h5>
        {isEditor && (
          <Flex justifyEnd>
            <Button handleClick={handleDelete} primary label={t('delete')} />
          </Flex>
        )}
      </Flex>
    </>
  );
}
