import Button from '../../../components/Button/Button';
import Flex from '../../../components/Flex/Flex';
import useCreateClass from './useCreateClass';

export default function CreateClass({ data, goBack }) {
  const { handleName, name, handleSubmit, handleColor, color, t, ref } = useCreateClass({
    data: data,
    goBack: goBack,
  });

  return (
    <Flex className="form">
      <form onSubmit={handleSubmit}>
        <Flex column className="item simple" gap={'20px'}>
          <h3>{t('newClass')}</h3>
          <small>{t('dataSheet')}</small>
          <div>
            <label>{t('name')}</label>
            <input ref={ref} type="text" required value={name} onChange={handleName} />
          </div>
          <div>
            <label>{t('color')}</label>
            <input type="color" required value={color} onChange={handleColor} />
          </div>
          <Flex justifyEnd gap={'10px'}>
            <Button gray handleClick={goBack} type="button" label={t('cancel')} />
            <Button primary={true} type="submit" label={t('save')} />
          </Flex>
        </Flex>
      </form>
    </Flex>
  );
}
