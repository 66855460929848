import React from 'react';
import Flex from '../Flex/Flex';
import useTemplates from './useTemplates';
import './Templates.scss';
import Nav from '../Nav/Nav';
import classNames from 'classnames';

const Templates = React.memo(() => {
  const { templates, t, handleSelect } = useTemplates();

  return (
    <>
      <Nav goTo="/activities" title={t('templates')} />
      <Flex column alignCenter>
        <h4>{t('choiceTemplate')}</h4>
        <Flex gap="20px" className="templates">
          {templates?.map((item, index) => (
            <Flex
              handleClick={() => handleSelect(item.id)}
              alignCenter
              column
              className={classNames('item', {
                [`--enabled-${item.enabled}`]: item.enabled !== undefined,
              })}
              key={index}
            >
              <img src={`/templates/ico_${item.name.toLowerCase()}.png`} alt={t(`templateTypes.${item.name}`)} />
              {t(`templateTypes.${item.name}`)}
            </Flex>
          ))}
        </Flex>
      </Flex>
    </>
  );
});

export default Templates;
