import { useEffect, useRef, useState } from 'react';
import PublicationsService from '../../../services/Publications.service';
import { useTranslation } from 'react-i18next';
import LicenseService from '../../../services/License.service';
import { publish } from '../../../utils/events';
import useToast from '../../../hooks/useToast';
import moment from 'moment';

export default function useCreateLicense({ data, goBack }) {
  const { t } = useTranslation();
  const { toast, updateToast } = useToast();
  const [name, setName] = useState(data?.name || '');
  const [qty, setQty] = useState(data?.qty || 1);
  const [published, setPublished] = useState([]);
  const [duration, setDuration] = useState(t('durations'));
  const [number, setNumber] = useState(1);
  const [startAt, setStartAt] = useState(moment().format('YYYY-MM-DD'));
  const [select, setSelect] = useState(t('durations')[0]);
  const ref = useRef();

  console.log(startAt);

  useEffect(() => {
    const fetch = async () => {
      const response = await PublicationsService.getForLicenses();
      response.forEach((element) => {
        let el = data && data.Publications?.find((it) => it.id === element.id);
        element.checked = el ? true : false;
      });

      setPublished(response);
    };
    fetch();
  }, []);

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleQty = (e) => {
    setQty(e);
  };

  const handleNumber = (e) => {
    setNumber(e);
  };

  const handleSelect = (e) => {
    setSelect(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const filteredItems = published.filter((item) => item.checked).map((item) => item.id);

    if (!filteredItems.length) {
      updateToast({ text: t('selectAtLeast') });
      return;
    }

    let prepareData = {
      uses: qty,
      qty: number,
      startAt: startAt,
      duration: select.value,
      publications: filteredItems,
    };

    if (data) {
      await LicenseService.put({ id: data.id, data: prepareData });
      updateToast({ text: t('updated'), background: toast.type.SUCCESS });
      goBack();

      return;
    }

    await LicenseService.post({ data: prepareData });
    goBack();
    updateToast({ text: t('cloudSave'), background: toast.type.SUCCESS });
  };

  const handleCheckBox = ({ item, value }) => {
    const _data = [...published];
    _data.find((it) => it.id === item.id).checked = value;
    setPublished(_data);
  };

  const handleStartAt = (e) => {
    setStartAt(e.target.value);
  };

  return {
    handleName,
    name,
    handleSubmit,
    ref,
    published,
    handleCheckBox,
    t,
    handleQty,
    qty,
    duration,
    handleSelect,
    number,
    handleNumber,
    startAt,
    handleStartAt,
  };
}
