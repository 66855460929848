import axios from 'axios';
import { getToken } from './Auth';

const environment = process.env.REACT_APP_ENVIROMENT;
const apiUrl = process.env[`REACT_APP_API_URL_${environment}`] || 'http://localhost:3000';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = apiUrl;

axios.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }
    if (token) {
      config.headers.Authorization = token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Manejo global de errores
    if (error.response) {
      //   console.error('Error Response:', error.response);

      switch (error.response.status) {
        case 400:

        case 401:
          // Manejo para errores de autenticación
          // Ejemplo: redirigir a la página de inicio de sesión
          break;
        case 403:
          // Manejo para errores de permisos
          break;
        case 404:
          // Manejo para recursos no encontrados
          throw error;
          break;
        case 500:
          // Manejo para errores del servidor
          break;
        default:
          // Manejo para otros errores
          break;
      }
    } else if (error.request) {
      // La solicitud fue hecha pero no se recibió respuesta
      console.error('Error Request:', error.request);
    } else {
      // Algo sucedió al configurar la solicitud que desencadenó un error
      //console.error('Error Message:', error.message);
    }

    return Promise.reject(error);
  }
);

export default axios;
