import Button from '../../components/Button/Button';
import Flex from '../../components/Flex/Flex';
import Loader from '../../components/Loader/Loader';
import useActivities from './useActivities';
import Table from '../../components/Table/Table';
import QuizCreator from '../QuizCreator/QuizCreator';
import './Activities.scss';
import Nav from '../../components/Nav/Nav';

import Preview from './components/Preview';

export default function Activities({ onClose, onSelect, fromStudio }) {
  const { dataTable, handleDecision, t, quiz, handleClose, handleExit, buttons, preview } = useActivities({
    onClose: onClose,
    onSelect: onSelect,
    fromStudio: fromStudio,
  });

  if (!dataTable) return <Loader />;

  return (
    <Flex column>
      {!onSelect && <Nav handleClick={handleExit} title={t('activities')} />}
      {onSelect && onClose && (
        <Flex alignCenter justifyEnd>
          <Button handleClick={handleClose} iconName={'X'} />
        </Flex>
      )}

      <Flex className={`activities --alone-${!onSelect}`}>
        {!quiz && <Table t={t} data={dataTable} handleDecision={handleDecision} forcePlus buttons={buttons} />}
        {quiz && <QuizCreator onSave={handleClose} data={quiz} />}
        {preview && <Preview data={preview.data} handleClose={handleClose} />}
      </Flex>
    </Flex>
  );
}
