export const es = {
  a: 'un',
  hi: 'Hola',
  actions: 'Acciones',
  action: 'Acción',
  activity: 'Actividad',
  activities: 'Actividades',
  addLicense: 'Agregar licencia',
  addOption: 'Agregar opción',
  addTag: 'Agregar etiqueta',
  birth: 'Fecha de nacimiento',
  cancel: 'Cancelar',
  classes: 'Clases',
  class: 'clase',
  cloudSave: 'Guardado en la nube',
  invalidEmail: 'E-mail inválido',
  code: 'Código',
  color: 'Color',
  content: 'Contenido',
  contentAdded: 'Contenido agregado con éxito',
  contentCreator: 'Creador de contenido',
  contentDeleted: 'Contenido eliminado',
  contentSettings: 'Configuración de contenido',
  contentType: 'Tipo de contenido',
  contentUpdated: 'Contenido actualizado',
  dataSheet: 'Ficha técnica',
  deleteContent: 'Eliminar contenido',
  description: 'Descripción',
  dni: 'DNI',
  dniInfo: 'DNI sin puntos (7 u 8 caracteres)',
  selectAtLeastOneClass: 'Selecciona al menos una clase',
  users: 'Usuarios',
  copy: 'Copiar código',
  updated: 'Datos actualizados',
  delete: 'Borrar',
  pdfPreview: 'Pdf: vista previa',
  durations: [
    { value: 'days', text: 'Días' },
    { value: 'months', text: 'Meses' },
    { value: 'years', text: 'Años' },
  ],
  dateFormat: 'DD/MM/YYYY',
  email: 'Correo electrónico',
  enableAutosave: 'Habilitar guardado automático',
  end: 'Has completado la actividad',
  errors: {
    email: 'Correo electrónico ya en uso',
  },
  exercises: {
    continue: 'Continuar',
    reset: 'Restablecer',
    completed: 'Has completado la actividad',
    score: 'Tu puntuación es ',
    of: ' de ',
    type: 'Tipo',
    enterTag: 'Introduce una etiqueta',
    addTag: 'Agregar etiqueta',
    score2: 'Puntuación',
    lastAccess: 'Realizado',
    templates: {
      mc: 'Múltiple opción simple',
    },
  },
  expiresAt: 'Expira el',
  for: 'para activar la licencia',
  generate: 'Generar licencias',
  here: 'aquí',
  image: 'Imagen',
  license: 'Licencia',
  login: {
    account: 'Administrar cuenta',
    email: 'Correo electrónico',
    here: 'aquí',
    myProfile: 'Mi perfil',
    or: 'o',
    password: 'Contraseña',
    retype: 'Repetir contraseña',
    newPassword: 'Nueva contraseña',
    signIn: 'Iniciar sesión',
    signOut: 'Cerrar sesión',
    signUp: 'Registrarse',
    init: 'Iniciar sesión',
    passwordError: 'Las contraseñas no coinciden.',
    passwordMin: 'La contraseña mínimo 8 caracteres',
  },
  name: 'Nombre',
  newClass: 'Nueva clase',
  newLicense: 'Nueva licencia',
  newUser: 'Nuevo usuario',
  noContents: '¡Redime una licencia para obtener tus contenidos!',
  noLicense: 'No tienes una licencia.',
  noRecords: 'No hay registros',
  option: 'Introduce una opción',
  or: 'o en un botón del panel de encabezado.',
  pdfFile: 'Subir archivo PDF',
  please: 'Por favor, complete los campos a continuación.',
  preview: 'Vista previa',
  profile: 'Perfil',
  publish: 'Publicar',
  published: 'Publicado',
  publications: 'Publicaciones',
  qty: 'Cantidad de usos',
  reset: 'Restablecer',
  save: 'Guardar',
  saveAll: 'Guardar todo',
  savedLocal: 'Guardado localmente',
  score: 'Tu puntuación es',
  search: 'Buscar',
  searchActivity: 'Buscar actividad',
  select: 'Selecciona la respuesta correcta',
  selectAtLeast: 'Seleccione al menos una publicación',
  students: 'Estudiantes',
  surname: 'Apellido',
  tags: 'Etiquetas',
  resources: 'Recursos',
  templateQuiz: {
    addOption: 'Agregar opción',
    addQuestion: 'Agregar pregunta',
    name: 'Creador de Cuestionarios',
    option: 'Introduce una opción',
    options: 'Opciones',
    question: 'Introduce tu pregunta',
    save: 'Guardar cuestionario',
    select: 'Selecciona la respuesta correcta',
    title: 'Introduce el título del cuestionario',
  },
  templates: 'Plantillas',
  choiceTemplate: 'Elegir plantilla',
  tips: {
    newContent: 'Haz clic en el icono del libro para crear contenido',
    newFile: 'Haz clic en el icono de archivo para comenzar',
    newLicense: 'Haz clic en el icono de licencia para canjear una',
  },
  title: 'Título',
  totalRecords: 'Número de registros',
  type: 'Tipo',
  writeHere: 'Escribamos una historia increíble',
  editor: {
    tool: {
      Text: 'Párrafo',
      Heading: 'Encabezado',
      List: 'Lista',
      Warning: 'Advertencia',
      Checklist: 'Lista de verificación',
      Quote: 'Cita',
      Code: 'Código',
      Delimiter: 'Delimitador',
      'Raw HTML': 'HTML sin procesar',
      Table: 'Tabla',
      Link: 'Enlace',
      Marker: 'Marcador',
      Bold: 'Negrita',
      Italic: 'Cursiva',
      InlineCode: 'Código en línea',
      Activity: 'Actividad',
      Image: 'Imagen',
    },

    blockTunes: {
      converter: {
        'Convert to': 'Convertir a',
      },
      delete: {
        Delete: 'Eliminar',
      },
      moveUp: {
        'Move up': 'Mover hacia arriba',
      },
      moveDown: {
        'Move down': 'Mover hacia abajo',
      },
      toggler: {
        clickToTune: 'Haz clic para ajustar',
        clickToDelete: 'Haz clic para eliminar',
        'or drag to move': 'o arrastra para mover',
      },
    },
    inlineToolbar: {
      converter: {
        ConvertTo: 'Convertir a',
      },
    },
    toolbar: {
      toolbox: {
        Add: 'Agregar',
      },
    },
    tools: {
      warning: {
        Title: 'Título',
        Message: 'Mensaje',
      },
      link: {
        'Add a link': 'Agregar un enlace',
      },
      stub: {
        'The block can not be displayed correctly.': 'El bloque no puede mostrarse correctamente',
      },
    },
  },
  calendar: {
    title: 'Calendario de eventos',
    set: {
      next: 'Sig',
      previous: 'Ant',
      today: 'Hoy',
      month: 'Mes',
      week: 'Semana',
      day: 'Día',
    },
    newEvent: 'Nuevo evento',
    event: 'Evento',
    choose: 'Selecciona calendario',
    new: 'Nuevo calendario',
    create: 'Crear',
    public: 'Evento público. Para todos los usuarios de la/s clases seleccionadas.',
    chooseClass: 'Seleccione una clase',
  },
  gradebook: 'libro de calificaciones',
  choosePublication: 'Selecciona publicación',
  brand: {
    jm: {
      speech: 'Sean por siempre alabados Jesús y María',
      loginSpeech: 'Bienvenido a Jesús María, la revolución educativa impulsada por inteligencia artificial.',
    },
    sas: {
      speech: 'Calidad y eficiencia, Siempre.',
      loginSpeech: '',
    },
    default: {
      speech: 'Semper altius',
      loginSpeech: 'Bienvenido a Brain, la revolución educativa impulsada por inteligencia artificial.',
    },
  },
  templateTypes: {
    SimpleChoice: 'Opción simple',
    MultipleChoice: 'Múltiple opción',
    Fill: 'Completar',
    DragDrop: 'Arrastrar y soltar',
    Crossword: 'Crucigrama',
    WordSearch: 'Sopa de letras',
    LinkingLine: 'Unir con flechas',
  },
  startAt: 'Comienza en',
  drawing: {
    pencil: 'Dibujar',
    eraser: 'Borrar',
    clear: 'Limpiar',
    save: 'Guardar',
    close: 'Cerrar',
  },
};
