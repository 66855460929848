import Face from './src/face';
import Hair from './src/hair';
import Hat from './src/hat';
import Eyes from './src/eyes';
import Glasses from './src/glasses';
import Ear from './src/ear';
import Nose from './src/nose';
import Mouth from './src/mouth';
import Shirt from './src/shirt';
import Flex from '../Flex/Flex';

export default function Pieces({ handleClick, element, color = '#F9C9B6' }) {
  const { type } = element;
  const { colors, size, style } = element.settings;

  const cmp = (_type) => {
    switch (_type) {
      case 'face':
        return <Face color={colors.data[colors.selected]} />;
      case 'hair':
        return <Hair style={style.data[style.selected]} color={colors.data[colors.selected]} />;
      case 'hat':
        return <Hat style={style.data[style.selected]} color={colors.data[colors.selected]} />;
      case 'eyes':
        return <Eyes style={style.data[style.selected]} />;
      case 'glasses':
        return <Glasses style={style.data[style.selected]} />;
      case 'ear':
        return <Ear size={size.data[size.selected]} color={color} />;
      case 'nose':
        return <Nose style={style.data[style.selected]} />;
      case 'mouth':
        return <Mouth style={style.data[style.selected]} />;
      case 'shirt':
        return <Shirt style={style.data[style.selected]} color={colors.data[colors.selected]} />;
      case 'bg':
        return <div className="color" style={{ background: colors.data[colors.selected] }} />;
    }
  };
  return (
    <Flex column gap={'8px'}>
      <div onClick={() => handleClick({ type: type, bg: false })} className="pieces">
        {cmp(type)}
      </div>
      {colors && (style || size) && (
        <div onClick={() => handleClick({ type: type, bg: true })} className="pieces">
          {cmp('bg')}
        </div>
      )}
    </Flex>
  );
}
