const getClassName = (level) => {
  switch (level) {
    case 2:
      return 'head2';
    case 3:
      return 'head3';
    case 4:
      return 'head4';
    default:
      return null;
  }
};

function isObject(obj) {
  return obj !== null && typeof obj === 'object';
}

function isEqual(obj1, obj2) {
  if (!isObject(obj1) || !isObject(obj2)) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !isEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

const hasDifferences = (obj1, obj2) => {
  return !isEqual(obj1, obj2);
};

const base64ToUtf8 = (str) => {
  const binaryString = atob(str);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new TextDecoder().decode(bytes);
};

const isValidDNI = (dni) => {
  // Eliminar espacios en blanco al inicio y final
  dni = dni.trim();

  // Verificar que el DNI sea un número y tenga entre 7 y 8 dígitos
  const dniRegex = /^\d{7,8}$/;
  return dniRegex.test(dni);
};

const getMainMenu = ({ t, isEditor }) => {
  if (isEditor) {
    return [
      {
        help: {
          caption: t('tips.newContent'),
        },
        linkTo: 'studio',
        caption: t('contentCreator'),
        icon: 'Book',
      },
      {
        separator: true,
      },
      {
        linkTo: 'activities',
        caption: t('activities'),
        icon: 'GraduationCap',
      },
      {
        linkTo: 'resources',
        caption: t('resources'),
        icon: 'Dribbble',
      },
      {
        linkTo: 'calendar',
        caption: t('calendar.title'),
        icon: 'CalendarHeart',
      },
      {
        separator: true,
      },
      {
        linkTo: 'classes',
        caption: t('classes'),
        icon: 'SquareUserRound',
      },
      {
        linkTo: 'users',
        caption: t('users'),
        icon: 'Users',
      },
      {
        separator: true,
      },
      {
        linkTo: 'licenses',
        caption: t('generate'),
        icon: 'PackagePlus',
        preventClick: true,
      },
      {
        separator: true,
      },
      {
        name: 'profile',
        caption: t('profile'),
        icon: 'CircleUser',
      },
    ];
  }

  return [
    {
      help: {
        caption: t('tips.newLicense'),
      },
      name: 'license',
      caption: t('license'),
      icon: 'TicketPlus',
    },
    {
      separator: true,
    },
    {
      linkTo: 'calendar',
      caption: t('calendar.title'),
      icon: 'CalendarHeart',
    },
    {
      linkTo: 'gradebook',
      caption: t('gradebook'),
      icon: 'GraduationCap',
    },
    {
      separator: true,
    },
    {
      name: 'profile',
      caption: t('profile'),
      icon: 'CircleUser',
    },
  ];
};

export { getClassName, hasDifferences, base64ToUtf8, isValidDNI, getMainMenu };
