import { useEffect, useState } from 'react';
import { publish, subscribe, unsubscribe } from '../../utils/events';
import { useRecoilState } from 'recoil';
import { toastState } from '../../utils/states';

export default function useToast() {
  const [text, setText] = useState('');
  const [show, setShow] = useState(false);
  const [toast, setToast] = useRecoilState(toastState);

  useEffect(() => {
    let timer;

    if (toast.text.length > 0) {
      timer = setTimeout(() => {
        setToast((prevToast) => ({
          ...prevToast,
          text: '',
          time: 1500,
          background: toast.type.WARN,
        }));
      }, toast.time || 1500);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [toast.text, toast.time, setToast]);

  const handlerToast = (e) => {
    setText(e.detail.text);
    setShow(true);
    setTimeout(() => {
      setShow(false);
      publish('HIDE_TOAST');
    }, e.detail.time || 1500);
  };

  useEffect(() => {
    subscribe('SHOW_TOAST', handlerToast);
    return () => {
      unsubscribe('SHOW_TOAST', handlerToast);
    };
  }, []);

  return {
    text: toast.text,
    show: toast.text.length > 0,
    toast,
  };
}
