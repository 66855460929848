import useGradebook from './useGradebook';
import './Gradebook.scss';
import Nav from '../../components/Nav/Nav';
import Flex from '../../components/Flex/Flex';
import ReactDropdown from 'react-dropdown';
import Table from '../../components/Table/Table';
import Quiz from '../Core/Quiz/Quiz';
import Loader from '../../components/Loader/Loader';

export default function Gradebook() {
  const { t, publications, handleSelectPublication, handleDecision, dataTable, buttons, quiz, handleGetSaved } =
    useGradebook();

  return (
    <>
      <Nav title={t('gradebook')} />
      <Flex className="form">
        <ReactDropdown options={publications} onChange={handleSelectPublication} placeholder={t('choosePublication')} />
      </Flex>
      <Flex justifyCenter className="gradebook" gap={'20px'}>
        <div className="item">
          {dataTable && <Table t={t} data={dataTable} handleDecision={handleDecision} buttons={buttons} />}
        </div>
        <div className="item">
          {quiz && (
            <Quiz data={quiz.Activity.data} userResponse={quiz.Users?.UserActivity} handleGetSaved={handleGetSaved} />
          )}
        </div>
      </Flex>
    </>
  );
}
