import ReactDropdown from 'react-dropdown';
import Block from '../../../components/Block/Block';
import Button from '../../../components/Button/Button';
import CheckBox from '../../../components/Checkbox/CheckBox';
import Flex from '../../../components/Flex/Flex';
import useCreateEvent from './useCreateEvent';

const CreateEvent = ({ t, handleClose, newDate, handleFinishEvent, calendarId }) => {
  const { classes, handleCheckBox, data, handleEventData, handleSubmitEvent } = useCreateEvent({
    newDate: newDate,
    handleFinishEvent: handleFinishEvent,
    CalendarId: calendarId,
  });
  return (
    <>
      <Block />
      <Flex className="event form">
        <form onSubmit={handleSubmitEvent}>
          <Flex column className="item simple" gap={'20px'}>
            <h3>{t('calendar.event')}</h3>
            <div>
              <label>{t('calendar.new')}</label>
              <input required type="text" value={data.title} name="title" onChange={handleEventData} />
            </div>
            <div>
              <label>{t('description')}</label>
              <textarea required value={data.description} name="description" onChange={handleEventData} />
            </div>

            <div>
              <label>{`${t('classes')}: ${t('calendar.public')}`}</label>
              <div className="scroll">
                {classes?.map((item, index) => {
                  return (
                    <Flex spaceBtw alignCenter key={`i_${index}`}>
                      <div>{item.name}</div>
                      <div className="flex">
                        <CheckBox
                          handleCheckBox={(e) => handleCheckBox({ item: item, value: e.target.checked })}
                          checked={item.checked}
                        />
                      </div>
                    </Flex>
                  );
                })}
              </div>
            </div>
            <Flex justifyEnd>
              <Button handleClick={handleClose} type="button" label={t('cancel')} />
              <Button primary={true} type="submit" label={t('save')} />
            </Flex>
          </Flex>
        </form>
      </Flex>
    </>
  );
};

export default CreateEvent;
