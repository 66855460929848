import { Link } from 'react-router-dom';
import Flex from '../../../components/Flex/Flex';
import QuizCreator from '../../QuizCreator/QuizCreator';
import Button from '../../../components/Button/Button';
import { withTranslation } from 'react-i18next';
import Nav from '../../../components/Nav/Nav';

const Create = ({ t, onClose }) => {
  return (
    <>
      <Nav goTo="/activities/templates" title={t('activity')} />
      <QuizCreator />
    </>
  );
};

export default withTranslation()(Create);
