import Flex from '../../../components/Flex/Flex';
import { ReactSortable } from 'react-sortablejs';
import { GripHorizontal } from 'lucide-react';
import Button from '../../../components/Button/Button';

export default function QuizQuestion({
  quiz,
  question,
  handleCollapseQuestion,
  handleDeleteOption,
  handleDeleteQuestion,
  handleAddOption,
  handleSetQuestion,
  handleSortQuestion,
  handleSetQuestionOption,
  sIndex,
  t,
}) {
  return (
    <Flex className={`options --collapse-${question.collapse || false}`} column>
      <Flex spaceBtw>
        <Flex>
          <GripHorizontal />
          <span className="question">#{sIndex + 1}</span>
        </Flex>
        <Flex gap={'1px'} alignCenter>
          <Button handleClick={() => handleCollapseQuestion({ index: sIndex })} iconName={'ChevronsDownUp'} />
          {quiz.length > 1 && <Button handleClick={() => handleDeleteQuestion({ index: sIndex })} iconName={'Trash'} />}
        </Flex>
      </Flex>
      <input
        type="text"
        placeholder={t('templateQuiz.question')}
        value={question.text}
        onChange={(e) => handleSetQuestion({ index: sIndex, key: 'text', value: e.target.value })}
      />

      <Flex className="options" column>
        <ReactSortable
          list={question.options}
          setList={(newState) => handleSortQuestion({ index: sIndex, newState: newState })}
        >
          {question.options.map((option, index) => (
            <Flex className={'opts'} key={`__${quiz.length}_${index}`} alignCenter>
              <GripHorizontal />
              <input
                type="text"
                placeholder={t('templateQuiz.option')}
                value={option.text}
                onChange={(e) => handleSetQuestionOption({ index: sIndex, id: option.id, value: e.target.value })}
              />
              <Button
                handleClick={() => handleDeleteOption({ index: sIndex, id: index })}
                disable={question.options.length < 3}
                iconName={'Trash'}
              />
            </Flex>
          ))}
        </ReactSortable>
      </Flex>
      <Button secondary handleClick={() => handleAddOption({ index: sIndex })} label={t('templateQuiz.addOption')} />
      <div className="quizSep"></div>
      <Flex className="options" column>
        <div>{t('templateQuiz.select')}</div>
        {question.options.map((option, index) => (
          <Flex key={index}>
            <label className="q-label">
              <input
                type="radio"
                name={`quiz_${sIndex}_${index}`}
                checked={question.correct_option === option.id}
                onChange={() => handleSetQuestion({ index: sIndex, key: 'correct_option', value: option.id })}
              />
              {option.text}
            </label>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
