import axios from './Client';

const get = async () => {
  const response = await axios.get(`/users/teacher`);
  return response.data;
};

const put = async ({ data, id }) => {
  try {
    const response = await axios.put(`/users/${id}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const post = async ({ data }) => {
  const response = await axios.post(`/users/teacher`, data);
  return response.data;
};

const getByStudent = async () => {
  const response = await axios.get(`/users/classes`);
  return response.data;
};

export default { get, put, post, getByStudent };
