import { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/AuthProvider';
import ContentsService from '../../services/Contents.service';
import LicenseService from '../../services/License.service';
import { useTranslation } from 'react-i18next';
import RedeemedService from '../../services/Redeemed.service';
import useToast from '../../hooks/useToast';

export default function useDash() {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { toast, updateToast } = useToast();
  const [contents, setContents] = useState([]);
  const [showProfile, setShowProfile] = useState(false);
  const [showLicense, setShowLicense] = useState(false);
  const [showBlock, setShowBlock] = useState(false);

  const getAllFromUser = async () => {
    try {
      const data = user.userType !== 'student' ? await ContentsService.all() : await RedeemedService.get();
      setContents(data);
    } catch (error) {
      updateToast({ text: error.response.data.message });
    }
  };

  useEffect(() => {
    getAllFromUser();
  }, []);

  const handleCloseProfile = () => {
    setShowProfile(false);
  };

  const handleCloseLicense = () => {
    setShowLicense(false);
    setShowBlock(false);
  };

  const handleSubmit = async (e, license) => {
    e.preventDefault();
    setShowBlock(true);
    try {
      const data = await LicenseService.reedem({ data: { code: license } });
      setContents(data);
      handleCloseLicense();
    } catch (err) {
      setShowBlock(false);
      updateToast({ text: err.response.data.message });
    }
  };

  const handleDecision = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.name === 'license' && setShowLicense(true);
    e.target.name === 'profile' && setShowProfile(!showProfile);
  };

  return {
    contents,
    showProfile,
    handleCloseProfile,
    handleCloseLicense,
    handleDecision,
    showLicense,
    handleSubmit,
    showBlock,
    path: process.env[`REACT_APP_API_URL_${process.env.REACT_APP_ENVIROMENT}`],
    isEditor: user.userType !== 'student',
    userMenu: user.utt,
    t,
  };
}
