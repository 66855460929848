import { atom } from 'recoil';

const textState = atom({
  key: 'textState', // Un identificador único para el átomo
  default: '', // Valor predeterminado del estado
});

const toastState = atom({
  key: 'toastState',
  default: {
    text: '',
    time: 1500,
    background: 'green',
    type: Object.freeze({
      WARN: 'warn',
      SUCCESS: 'success',
      INFO: 'info',
    }),
  },
});

export { toastState, textState };
