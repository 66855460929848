import { useEffect, useState } from 'react';

export const useTenantMeta = (tenant) => {
  const [metaConfig, setMetaConfig] = useState(null);

  useEffect(() => {
    const fetchMetaConfig = async () => {
      try {
        const response = await fetch(`/${tenant}/metaConfig.json`);
        const config = await response.json();
        setMetaConfig(config);
      } catch (error) {
        console.error('Error al cargar la configuración de metadatos', error);
      }
    };

    fetchMetaConfig();
  }, [tenant]);

  useEffect(() => {
    if (metaConfig) {
      document.title = metaConfig.title || 'Mi Aplicación';

      const updateMetaTag = (name, content) => {
        let tag = document.querySelector(`meta[name='${name}']`);
        if (tag) {
          tag.content = content;
        } else {
          const newTag = document.createElement('meta');
          newTag.name = name;
          newTag.content = content;
          document.head.appendChild(newTag);
        }
      };

      updateMetaTag('description', metaConfig.description);
      updateMetaTag('keywords', metaConfig.keywords);

      const updateOgTag = (property, content) => {
        let tag = document.querySelector(`meta[property='${property}']`);
        if (tag) {
          tag.content = content;
        } else {
          const newTag = document.createElement('meta');
          newTag.setAttribute('property', property);
          newTag.content = content;
          document.head.appendChild(newTag);
        }
      };

      updateOgTag('og:title', metaConfig.title);
      updateOgTag('og:description', metaConfig.description);
    }
  }, [metaConfig]);
};
