import React from 'react';

export default function noseRound() {
  return (
    <svg viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.307 12.3397C17.753 11.0993 26.6843 12.9603 24.7238 22.8833C22.9813 31.7023 13.6141 32.1857 11 29.7048"
        stroke="black"
        strokeWidth="4"
      />
    </svg>
  );
}
