import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function useLicense() {
  const [license, setLicense] = useState('');
  const { t } = useTranslation();

  const handleChange = (e) => {
    setLicense(e.target.value);
  };

  return { license, handleChange, t };
}
