import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CoreConfig } from '../commons/CoreConfig';

export default function useQuiz({ data, handleSave, handleGetSaved, id, userResponse }) {
  const [quiz, setQuiz] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const { t } = useTranslation();
  const { baseScore } = CoreConfig;

  useEffect(() => {
    setScore(0);
    setShowScore(false);
    setQuiz(data);
    async function fetchData() {
      let response = null;
      if (!userResponse && handleGetSaved) {
        response = await handleGetSaved({ ActivityId: data.id, id: id });
      }
      if (userResponse || response) {
        //setSelectedOption(userResponse.response[0]);
        setScore(userResponse.score);
        setShowScore(true);
      }
    }
    fetchData();
  }, [data]);

  const handleOptionChange = (optionId) => {
    setSelectedOption(optionId);
  };

  const saveActivity = ({ score }) => {
    handleSave &&
      handleSave({
        result: { ActivityId: data.id, response: quiz.questions.map((item) => item.response), score: score },
      });
  };

  const handleSubmit = () => {
    if (quiz) {
      const currentQuestion = quiz.questions[currentQuestionIndex];
      currentQuestion.response = selectedOption;
      let sco = score + (selectedOption === currentQuestion.correct_option ? 1 : 0);
      console.log(sco, baseScore, quiz.questions.length);
      sco = Math.round((sco * baseScore) / quiz.questions.length);
      setScore(sco);

      if (selectedOption === currentQuestion.correct_option) {
      }
      const nextQuestionIndex = currentQuestionIndex + 1;
      if (nextQuestionIndex < quiz.questions.length) {
        setCurrentQuestionIndex(nextQuestionIndex);
        setSelectedOption(null);
      } else {
        setShowScore(true);
        saveActivity({ score: sco });
      }
    }
  };

  const handleReset = () => {
    setShowScore(false);
    setScore(0);
    setCurrentQuestionIndex(0);
    setSelectedOption(null);
  };

  return {
    handleOptionChange,
    handleSubmit,
    handleReset,
    quiz,
    currentQuestionIndex,
    showScore,
    selectedOption,
    score,
    t,
  };
}
