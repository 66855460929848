import { useRef, useState } from 'react';
import { useAuth } from '../../hooks/AuthProvider';
import { useTranslation } from 'react-i18next';
import UsersService from '../../services/Users.service';
import _ from 'lodash';
import useToast from '../../hooks/useToast';

export default function useAccount() {
  const { t } = useTranslation();
  const { user, setUser } = useAuth();
  const { toast, updateToast } = useToast();
  const [data, setData] = useState({ name: user.name, password: '', newPassword: '', avatar: user.avatar });
  const ref = useRef(null);

  const handleUser = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleChangeAvatar = (avatar) => {
    setData({ ...data, avatar: avatar });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let payload = {};

    if (data.password.length > 0 || data.newPassword.length > 0) {
      if (data.newPassword.trim().length < 8) {
        updateToast({ text: t('login.passwordMin') });
        return;
      }
      payload.password = data.password;
      payload.newPassword = data.newPassword;
    }

    if (!_.isEqual(user.avatar, data.avatar)) {
      payload.avatar = data.avatar;
    }

    if (data.name !== user.name) {
      payload.name = data.name;
    }

    if (_.isEmpty(payload)) return;

    try {
      await UsersService.put({ data: payload, id: user.id });
      setUser({ ...user, avatar: payload?.avatar || user.avatar, name: payload.name || user.name });
      updateToast({ text: t('updated'), background: toast.type.SUCCESS });
    } catch (error) {
      updateToast({ text: error.response.data.message });
    }
  };

  return {
    handleSubmit,
    handleUser,
    handleChangeAvatar,
    data,
    t,
    ref,
  };
}
