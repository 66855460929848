import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function useResources() {
  const { t } = useTranslation();
  const [showDices, setShowDices] = useState(false);

  const handleButton = () => {
    setShowDices(!showDices);
  };
  return { t, handleButton, showDices };
}
