import { useEffect, useState } from 'react';
import ClassesService from '../../../services/Classes.service';
import CalendarsService from '../../../services/Calendars.service';

export default function useCreateEvent({ newDate, handleFinishEvent, CalendarId }) {
  const [classes, setClasses] = useState(null);
  const [data, setData] = useState({ ...newDate, ...{ title: '', description: '' } });

  useEffect(() => {
    const fetch = async () => {
      const data = await ClassesService.get();
      data.forEach((element) => {
        element.checked = false;
      });
      setClasses(data);
    };
    fetch();
  }, []);

  const handleCheckBox = ({ item, value }) => {
    setClasses(classes.map((it) => (it.id === item.id ? { ...it, checked: value } : it)));
  };

  const handleEventData = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    const filteredItems = classes.filter((item) => item.checked).map((item) => item.id);
    const response = await CalendarsService.postEvent({
      data: { event: data, calendarId: CalendarId, classIds: filteredItems },
    });
    handleFinishEvent(response);
  };

  return { classes, handleCheckBox, handleEventData, handleSubmitEvent, data };
}
