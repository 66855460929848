import React from 'react';
import Flex from '../../components/Flex/Flex';
import Button from '../../components/Button/Button';
import './QuizCreator.scss';
import { ReactSortable } from 'react-sortablejs';

import useQuizCreator from './useQuizCreator';
import Quiz from '../Core/Quiz/Quiz';
import Loader from '../../components/Loader/Loader';
import Tags from '../../components/Tags/Tags';
import QuizQuestion from './components/QuizQuestion';

export default function QuizCreator({ onSave, data }) {
  const {
    handleAddOption,
    handleAddQuestion,
    handleSaveQuiz,
    t,
    handleSetQuestion,
    handleSetQuestionOption,
    handleTitleQuiz,
    handleSort,
    handleSortQuestion,
    handleCollapseQuestion,
    handleDeleteOption,
    handleDeleteQuestion,
    handleChangeTags,
    handleReset,
    handleDescriptionQuiz,
    description,
    quiz,
    title,
    tags,
  } = useQuizCreator({ data: data, onSave: onSave });

  if (!quiz.length) return <Loader title="Loading" />;

  return (
    <Flex className="quiz-creator" column>
      <Flex spaceBtw>
        <Flex column className="form viewer">
          <Flex column>
            <form onSubmit={handleSaveQuiz}>
              <Flex spaceBtw>
                <h3>{t('exercises.templates.mc')}</h3>
                <Button type="submit" iconName={'Save'} label={t('save')} />
              </Flex>
              <input
                type="text"
                required
                placeholder={t('templateQuiz.title')}
                onChange={handleTitleQuiz}
                value={title}
              />
              <textarea
                required
                onChange={handleDescriptionQuiz}
                value={description}
                placeholder={t('description')}
              ></textarea>
              <label>{t('tags')}</label>
              <Tags t={t} dataTags={tags} handleChange={handleChangeTags} />
            </form>
          </Flex>
          <Flex justifyEnd gap={'5px'}>
            <Button handleClick={handleAddQuestion} iconName={'Plus'} caption={t('templateQuiz.addQuestion')} />
            <Button handleClick={handleReset} iconName={'ListRestart'} caption={t('exercises.reset')} />
          </Flex>
          <Flex column={true}>
            <ReactSortable list={quiz} setList={(newState) => handleSort(newState)}>
              {quiz.map((question, sIndex) => (
                <QuizQuestion
                  key={`____${sIndex}`}
                  sIndex={sIndex}
                  t={t}
                  quiz={quiz}
                  question={question}
                  handleCollapseQuestion={handleCollapseQuestion}
                  handleDeleteOption={handleDeleteOption}
                  handleDeleteQuestion={handleDeleteQuestion}
                  handleAddOption={handleAddOption}
                  handleSetQuestion={handleSetQuestion}
                  handleSortQuestion={handleSortQuestion}
                  handleSetQuestionOption={handleSetQuestionOption}
                />
              ))}
            </ReactSortable>
          </Flex>
        </Flex>

        <Flex className="viewer" column>
          <Quiz
            data={{
              title: title,
              description: description,
              type: 'multiple-choice',
              language: 'es',
              version: '1.0',
              questions: quiz,
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
