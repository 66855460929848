import './Resources.scss';
import Flex from '../../components/Flex/Flex';
import Button from '../../components/Button/Button';
import useResources from './useResources';
import RouletteGame from './components/RouletteGame';
import ReactDice, { ReactDiceRef } from 'react-dice-complete';
import { useRef } from 'react';
import Nav from '../../components/Nav/Nav';

export default function Resources() {
  const { t, handleButton, showDices } = useResources();
  const reactDice = useRef(null);

  const rollDone = (totalValue, values) => {
    console.log('individual die values array:', values);
    console.log('total dice value:', totalValue);
  };

  const rollAll = () => {
    reactDice.current?.rollAll();
  };

  return (
    <Flex column>
      <Nav goTo={'/'} title={t('resources')} />
      <Flex alignCenter gap={'20px'} justifyCenter>
        <Button label={'Ruleta'} handleClick={handleButton} primary />
        <Button label={'Dados'} handleClick={handleButton} primary />
      </Flex>
      <Flex justifyCenter>
        <div style={{ marginTop: 100 }}>
          {!showDices && <RouletteGame />}
          {showDices && (
            <div gap={'55px'}>
              <>
                <Button primary label={'roll'} handleClick={rollAll} />
                <ReactDice
                  dieSize={120}
                  faceColor="var(--color-note)"
                  dotColor={'#FFF'}
                  numDice={2}
                  margin={20}
                  ref={reactDice}
                  rollDone={rollDone}
                />
              </>
            </div>
          )}
        </div>
      </Flex>
    </Flex>
  );
}
