import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import(`./styles/${process.env.REACT_APP_TENANT}.scss`);
import './_index.scss';
import App from './App';
const root = ReactDOM.createRoot(document.getElementById('root'));

console.log(
  '%c~ Brain Frontend',
  'color: #3b1d0a; font-size: 12px; font-weight:bold',
  `\nversion: ${process.env.REACT_APP_VERSION}`
);
root.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>
);
