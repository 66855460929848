import { Link } from 'react-router-dom';
import './Article.scss';
import useArticle from './useArticle';

export default function Article({ item, isEditor }) {
  const { image, t, handleView } = useArticle({ item: item, isEditor: isEditor });

  return (
    <article>
      <div className="article-wrapper">
        <figure>
          <img src={image} alt="" />
        </figure>
        <div className="article-body">
          <h2>{item.title}</h2>
          {isEditor && (
            <small>
              {t('published')}:{' '}
              {item.Publications?.length > 0 ? (
                <span>{new Date(item.Publications[0].updatedAt).toLocaleString('en-GB', { timeZone: 'UTC' })}</span>
              ) : (
                '-'
              )}
            </small>
          )}

          <p className="description">{item.description}</p>
          <Link onClick={handleView} className="read-more" />
        </div>
      </div>
    </article>
  );
}
