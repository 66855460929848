import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClassesService from '../../../services/Classes.service';
import useToast from '../../../hooks/useToast';

export default function useCreateClass({ data, goBack }) {
  const { t } = useTranslation();
  const { toast, updateToast } = useToast();
  const [name, setName] = useState(data?.name || '');
  const [color, setColor] = useState('#111');
  const ref = useRef(null);

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleColor = (e) => {
    setColor(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let prepareData = {
      name: name,
      color: color,
    };

    if (data) {
      await ClassesService.put({
        id: data.id,
        data: prepareData,
      });
      updateToast({ text: t('updated'), background: toast.type.SUCCESS });
      goBack();

      return;
    }

    await ClassesService.post({ data: prepareData });
    updateToast({ text: t('cloudSave'), background: toast.type.SUCCESS });
    setName('');
    ref.current.focus();
  };

  return {
    handleName,
    name,
    handleSubmit,
    handleColor,
    color,
    t,
    ref,
  };
}
