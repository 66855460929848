import Button from '../../components/Button/Button';
import Flex from '../../components/Flex/Flex';
import useStudio from './useStudio';
import './Studio.scss';
import { Link } from 'react-router-dom';
import Set from './components/settings/Set';
import Separator from '../../components/Separator/Separator';
import Trees from './components/tree/Tree';
import CheckBox from '../../components/Checkbox/CheckBox';
import Modal from '../../components/Modal/Modal';
import Activities from '../Activities/Activities';
import Loader from '../../components/Loader/Loader';
import Nav from '../../components/Nav/Nav';

export default function Studio() {
  const {
    handleSave,
    handleShowSet,
    showSet,
    handleTree,
    autoSave,
    handleAutoSave,
    handleSelect,
    guid,
    t,
    handleCloseSet,
    readOnly,
    handleView,
    handlePublish,
    showModal,
    handleClose,
    handleSelectActivity,
    data,
    noData,
  } = useStudio();

  if (!data && !noData) return <Loader />;

  return (
    <Flex className="studio" column gap={'0px'}>
      <Modal show={showModal} cmp={<Activities fromStudio onSelect={handleSelectActivity} onClose={handleClose} />} />
      <Nav title={data?.title || data?.Content?.title || ''}>
        {guid && (
          <Flex>
            <Flex>
              <label className="cbox">
                <CheckBox checked={autoSave} handleCheckBox={handleAutoSave} />
                {t('enableAutosave')}
              </label>
            </Flex>
            <Separator />
            <Button disable={readOnly} caption={t('save')} handleClick={() => handleSave(false)} iconName={'Save'} />
            <Button disable={readOnly} caption={t('preview')} handleClick={handleView} iconName={'Eye'} />
            <Separator />
            <Button
              disable={readOnly}
              caption={t('saveAll')}
              handleClick={() => handleSave(true)}
              iconName={'SaveAll'}
            />
            <Button disable={readOnly} caption={t('publish')} handleClick={handlePublish} iconName={'Rocket'} />
            <Button caption={t('settings')} handleClick={handleShowSet} iconName={'Settings'} />
          </Flex>
        )}
      </Nav>
      {(showSet || !guid) && <Set id={guid} data={data} handleClose={handleCloseSet} />}
      {guid && (
        <Flex gap={'40px'}>
          <Trees t={t} dataTree={data.data} handleSelect={handleSelect} handleCloseBlock={handleTree} />
          <div style={{ position: 'relative' }}>
            <div className="editor" id="editorjs"></div>
          </div>
        </Flex>
      )}
    </Flex>
  );
}
